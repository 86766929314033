:root {
  --mdc-fab-small-container-shape: 50%;
  --mdc-filled-button-label-text-weight: 500;
  --mdc-outlined-button-label-text-weight: 500;
  --mdc-outlined-text-field-container-shape: var(--mdc-shape-small);
  --mdc-icon-button-state-layer-size: 3rem;
}
.mat-mdc-unelevated-button {
  &.mat-primary {
    --mdc-filled-button-container-color: var(--button-primary-default);
    --mdc-filled-button-label-text-color: var(--button-primary-label);
    & > .mat-ripple.mat-mdc-button-ripple {
      --mat-filled-button-ripple-color: var(--button-primary-pressed);
    }
    &:hover:not(.cdk-mouse-focused) {
      --mdc-filled-button-container-color: var(--button-primary-hovered);
    }
    &:focus:not(.cdk-mouse-focused) {
      --mdc-filled-button-container-color: var(--button-primary-focused);
      outline: 2px solid var(--border-brand-light);
    }
  }

  &.mat-accent {
    --mdc-filled-button-label-text-color: var(--button-primary-label);
  }

  &.mat-warn {
    --mdc-filled-button-container-color: var(--critical-surface);
    --mdc-filled-button-label-text-color: var(--button-primary-label);
    & > .mat-ripple.mat-mdc-button-ripple {
      --mat-filled-button-ripple-color: var(--critical-surface-darker);
    }
    &:hover:not(.cdk-mouse-focused) {
      --mdc-filled-button-container-color: var(--critical-surface-dark);
    }
    &:focus:not(.cdk-mouse-focused) {
      --mdc-filled-button-container-color: var(--critical-surface-dark);
      outline: 2px solid var(--critical-border-light);
    }
  }

  & > .mat-ripple.mat-mdc-button-ripple {
    --mat-mdc-button-ripple-color: var(--button-primary-default);
  }

  & > .mat-mdc-button-persistent-ripple {
    --mat-mdc-button-persistent-ripple-color: transparent;
  }
}

.mat-mdc-raised-button {
  &.mat-primary {
    --mdc-protected-button-label-text-color: var(--button-primary-label);
  }

  &.mat-accent {
    --mdc-protected-button-label-text-color: var(--button-primary-label);
  }

  &.mat-warn {
    --mdc-protected-button-label-text-color: var(--button-primary-label);
  }
}

cca-language-switch .mat-mdc-button {
  --mdc-text-button-container-shape: 0.5rem;
  &:hover > .mat-mdc-button-persistent-ripple::before {
    opacity: 1;
    background: var(--surface-brand-lighter);
  }

  & > .mat-ripple.mat-mdc-button-ripple {
    --mat-text-button-ripple-color: var(--surface-brand-light);
  }
}

.mat-mdc-button {
  --mdc-text-button-disabled-label-text-color: var(--text-neutral-disabled);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  &.mat-accent:not(:disabled) {
    --mdc-text-button-label-text-color: var(--button-subtle-label-default);
  }
  &.mat-primary:not(:disabled) {
    --mat-text-button-state-layer-color: var(--button-primary-default);
    --mdc-text-button-label-text-color: var(--button-text-label);
  }
}

.mat-unthemed {
  &.mat-primary {
    --mdc-filled-button-label-text-color: var(--button-primary-label);
  }
  & > .mat-ripple.mat-mdc-button-ripple {
    --mat-mdc-button-ripple-color: var(--surface-neutral-default);
  }

  &.mat-primary:hover:not(.cdk-mouse-focused) {
    --mdc-filled-button-label-text-color: var(--button-text-label);
    --mdc-filled-button-container-color: var(--surface-brand-light);
  }

  & > .mat-mdc-button-persistent-ripple {
    --mat-mdc-button-persistent-ripple-color: transparent;
  }
}

.mat-mdc-outlined-button {
  &.mat-primary:not(:disabled) {
    --mdc-outlined-button-label-text-color: var(--text-brand-default);
    --mdc-outlined-button-outline-color: var(--border-brand-default);

    & > .mat-ripple.mat-mdc-button-ripple {
      --mat-mdc-button-ripple-color: var(--surface-brand-lightest);
      border: 1.5px solid transparent;
    }
    &:hover:not(.cdk-mouse-focused) {
      background: var(--surface-brand-lightest);
    }
    &:focus:not(.cdk-mouse-focused) {
      background: var(--surface-brand-lighter);
      border: 1px solid var(--border-brand-light);
      outline: 1px solid var(--border-brand-light);
    }
  }

  &.mat-warn:not(:disabled) {
    --mdc-outlined-button-label-text-color: var(--button-text-label-critical);
    --mdc-outlined-button-outline-color: var(--critical-border);

    & > .mat-ripple.mat-mdc-button-ripple {
      border: 1.5px solid transparent;
    }
    &:hover:not(.cdk-mouse-focused) {
      --mdc-outlined-button-label-text-color: var(--critical-text-dark);
      --mdc-outlined-button-outline-color: var(--critical-border-dark);
    }
    &:focus:not(.cdk-mouse-focused) {
      border: 1px solid var(--critical-border-dark);
    }
  }

  &.mat-accent:not(:disabled) {
    --mdc-outlined-button-label-text-color: var(--button-subtle-label-default);
    border: 1px solid var(--button-subtle-border-default);

    & > .mat-ripple.mat-mdc-button-ripple {
      border: 1px solid transparent;
    }
    &:hover:not(.cdk-mouse-focused) {
      --mdc-outlined-button-label-text-color: var(
        --button-subtle-label-hovered
      );
      border: 1px solid var(--button-subtle-border-hovered);
    }
    &:focus:not(.cdk-mouse-focused) {
      border: 1px solid var(--button-subtle-border-focused);
    }
  }
  &:disabled {
    --mdc-outlined-button-disabled-outline-color: var(--button-disabled);
    --mdc-outlined-button-disabled-label-text-color: var(--button-disabled);
  }
}

.mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: var(--surface-neutral-disabled);
  --mdc-filled-button-disabled-label-text-color: var(--text-neutral-invert);
  --mdc-filled-button-container-color: var(--surface-neutral-disabled);
  --mdc-filled-button-label-text-color: var(--text-neutral-invert);
}

.mat-mdc-icon-button.mat-mdc-button-base {
  &.mat-primary {
    --mdc-icon-button-icon-color: var(--button-primary-default);
    --mat-icon-button-state-layer-color: rgba(111, 143, 47, 0.1);
    --mat-icon-button-ripple-color: rgba(111, 143, 47, 0.1);
  }
  &.mat-warn {
    --mdc-icon-button-icon-color: var(--critical-text-light);
    --mat-icon-button-state-layer-color: rgba(185, 28, 28, 0.1);
    --mat-icon-button-ripple-color: rgba(185, 28, 28, 0.1);
  }
}

// center spinner MDC
.mdc-button .mdc-button__label {
  display: flex;
  letter-spacing: 0;
  align-items: center;
}

.mat-mdc-button.mat-mdc-button-base,
.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
  height: 3rem;
  padding: 0 1rem;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0;
  --mdc-outlined-button-container-shape: var(--mdc-shape-small);
  --mdc-filled-button-container-shape: var(--mdc-shape-small);
  --mdc-text-button-container-shape: var(--mdc-shape-small);

  cca-icon {
    font-size: 1.5rem;
  }
}

button.mdc-icon-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

//button icon
.icon-button-small {
  @apply h-9 w-9 min-w-9 #{!important};
  cca-icon {
    @apply text-xl #{!important};
  }
}

.button-square {
  @apply w-12 min-w-12 p-0 #{!important};
}

.button-small {
  min-width: auto !important;
  @apply h-9 px-3 text-sm #{!important};
}

.icon-button-extra-small-with-text {
  height: 1rem !important;
  min-width: auto !important;
  @apply text-sm #{!important};
  cca-icon {
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
  }
}

.icon-button-extra-small {
  height: 1rem !important;
  width: 1rem !important;
  min-width: 1rem !important;
  cca-icon {
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
  }
}

.link {
  @apply border-b border-solid text-sm font-medium border-brand-default text-brand-default;
}

.mat-mdc-icon-button {
  .mdc-button__label,
  mat-icon {
    z-index: 1;
    position: relative;
  }
}
