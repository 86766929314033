:root {
  --mat-stepper-container-color: var(--surface-neutral-light);
  .mat-vertical-stepper-header {
    --mat-stepper-header-selected-state-icon-foreground-color: var(
      --text-neutral-invert
    );
    --mat-stepper-header-selected-state-icon-background-color: var(
      --surface-brand-default
    );
    --mat-stepper-header-edit-state-icon-foreground-color: var(
      --text-neutral-invert
    );
    --mat-stepper-header-edit-state-icon-background-color: var(
      --surface-brand-default
    );
    --mat-stepper-header-icon-background-color: var(--form-label);
    --mat-stepper-header-icon-foreground-color: var(--text-neutral-invert);
    --mat-stepper-line-color: var(--border-neutral-default);
    --mat-stepper-header-label-text-color: var(--text-neutral-caption);
  }
  .mat-horizontal-stepper-wrapper {
    --mat-stepper-header-icon-background-color: var(--surface-neutral-default);
    --mat-stepper-header-icon-foreground-color: var(--surface-neutral-default);
    --mat-stepper-header-edit-state-icon-foreground-color: var(
      --text-neutral-invert
    );
  }
}
.mat-step-header {
  --mat-stepper-header-hover-state-layer-color: none;
  --mat-stepper-header-focus-state-layer-color: none;
  .mat-step-header-ripple {
    display: none;
  }
}

.mat-stepper-vertical .mat-vertical-stepper-header {
  .mat-step-icon-state-edit {
    @apply flex items-center justify-center surface-brand-default border-brand-default;
    mat-icon {
      display: none;
    }

    &::after {
      @apply block;
      content: '\e9a3'; // pencil icon
      font-family: CtrlChainIcons;
    }
  }
}

.mat-stepper-horizontal:has(.mat-step-header:only-child) {
  @apply pt-6;
}

.mat-horizontal-stepper-wrapper {
  @apply gap-6;

  .mat-horizontal-stepper-header {
    @apply h-12 overflow-visible p-0;
  }

  .mat-horizontal-stepper-header-container:has(.mat-step-header:only-child) {
    display: none;
  }

  .mat-horizontal-stepper-header-container {
    @apply h-12 justify-center border-b px-6 surface-neutral-default border-neutral-default;

    .mat-stepper-horizontal-line {
      display: none;
    }

    .mat-step-header:not(:first-child)::before {
      @apply relative mx-2 block h-0.5 w-24 neutral-surface-dark;
      content: '';
    }

    .mat-step-header[ng-reflect-state='edit']:not(:first-child)::before,
    .mat-step-header[ng-reflect-active='true']:not(:first-child)::before {
      @apply surface-brand-default;
    }

    .mat-step-icon {
      @apply border-2 border-neutral-dark;
      span {
        @apply h-2 w-2 rounded-full;
        font-size: 0;
      }
    }

    .mat-step-icon-state-edit {
      @apply flex items-center justify-center surface-brand-default border-brand-default;
      mat-icon {
        display: none;
      }

      &::after {
        @apply block;
        content: '\e92d'; // check icon
        font-family: CtrlChainIcons;
      }
    }

    .mat-step-icon-selected {
      @apply border-brand-default;
      span {
        @apply surface-brand-default;
      }
    }
    .mat-step-label {
      @apply text-base text-neutral-body;
      &.mat-step-label-selected {
        @apply font-medium;
      }
    }
  }
}
