@font-face {
  font-family: CtrlChainIcons;
  src: url('./CtrlChain.ttf') format('truetype');
  src: url('./CtrlChain.eot?jvlm46');
  src:
    url('./CtrlChain.eot?jvlm46#iefix') format('embedded-opentype'),
    url('./CtrlChain.ttf?jvlm46') format('truetype'),
    url('./CtrlChain.woff?jvlm46') format('woff'),
    url('./CtrlChain.svg?jvlm46#ChainCargo') format('svg');
}

.cca-icon {
  font-family: CtrlChainIcons;
  position: relative;
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cca-icon-about-us:before {
  content: "\e900";
}
.cca-icon-address-card:before {
  content: "\e901";
}
.cca-icon-alarm-clock:before {
  content: "\e902";
}
.cca-icon-alignCenter:before {
  content: "\e903";
}
.cca-icon-alignJustify:before {
  content: "\e904";
}
.cca-icon-alignLeft:before {
  content: "\e905";
}
.cca-icon-alignRight:before {
  content: "\e906";
}
.cca-icon-android-face-Icon:before {
  content: "\e907";
}
.cca-icon-android-fingerprint:before {
  content: "\e908";
}
.cca-icon-arrow-curve-down-right:before {
  content: "\e909";
}
.cca-icon-arrow-down:before {
  content: "\e90a";
}
.cca-icon-arrow-down-left:before {
  content: "\e90b";
}
.cca-icon-arrow-down-right:before {
  content: "\e90c";
}
.cca-icon-arrow-left:before {
  content: "\e90d";
}
.cca-icon-arrow-right:before {
  content: "\e90e";
}
.cca-icon-arrow-rotate-left:before {
  content: "\e90f";
}
.cca-icon-arrow-rotate-right:before {
  content: "\e910";
}
.cca-icon-arrow-up:before {
  content: "\e911";
}
.cca-icon-arrow-up-left:before {
  content: "\e912";
}
.cca-icon-balance:before {
  content: "\e913";
}
.cca-icon-ban:before {
  content: "\e914";
}
.cca-icon-bar-chart:before {
  content: "\e915";
}
.cca-icon-bell:before {
  content: "\e916";
}
.cca-icon-bell-off:before {
  content: "\e917";
}
.cca-icon-Bold:before {
  content: "\e918";
}
.cca-icon-book:before {
  content: "\e919";
}
.cca-icon-booking:before {
  content: "\e91a";
}
.cca-icon-bookmark:before {
  content: "\e91b";
}
.cca-icon-box:before {
  content: "\e91c";
}
.cca-icon-box-check:before {
  content: "\e91d";
}
.cca-icon-boxes:before {
  content: "\e91e";
}
.cca-icon-box-taped:before {
  content: "\e91f";
}
.cca-icon-briefcase:before {
  content: "\e920";
}
.cca-icon-brush:before {
  content: "\e921";
}
.cca-icon-building:before {
  content: "\e922";
}
.cca-icon-calendar:before {
  content: "\e923";
}
.cca-icon-calendar-1:before {
  content: "\e924";
}
.cca-icon-calendar-check:before {
  content: "\e925";
}
.cca-icon-car:before {
  content: "\e926";
}
.cca-icon-caret-down:before {
  content: "\e927";
}
.cca-icon-caret-down-1:before {
  content: "\e928";
}
.cca-icon-caret-left:before {
  content: "\e929";
}
.cca-icon-caret-right:before {
  content: "\e92a";
}
.cca-icon-caret-up:before {
  content: "\e92b";
}
.cca-icon-caret-up-1:before {
  content: "\e92c";
}
.cca-icon-check:before {
  content: "\e92d";
}
.cca-icon-check-circle:before {
  content: "\e92e";
}
.cca-icon-check-double:before {
  content: "\e92f";
}
.cca-icon-chevron-down:before {
  content: "\e930";
}
.cca-icon-chevron-left:before {
  content: "\e931";
}
.cca-icon-chevron-right:before {
  content: "\e932";
}
.cca-icon-chevrons-down:before {
  content: "\e933";
}
.cca-icon-chevrons-left:before {
  content: "\e934";
}
.cca-icon-chevrons-right:before {
  content: "\e935";
}
.cca-icon-chevrons-up:before {
  content: "\e936";
}
.cca-icon-chevron-up:before {
  content: "\e937";
}
.cca-icon-child:before {
  content: "\e938";
}
.cca-icon-circle-arrow-left:before {
  content: "\e939";
}
.cca-icon-circle-arrow-right:before {
  content: "\e93a";
}
.cca-icon-circle-exclamation:before {
  content: "\e93b";
}
.cca-icon-circle-info:before {
  content: "\e93c";
}
.cca-icon-circle-notch:before {
  content: "\e93d";
}
.cca-icon-circle-small:before {
  content: "\e93e";
}
.cca-icon-circle-xmark:before {
  content: "\e93f";
}
.cca-icon-clear:before {
  content: "\e940";
}
.cca-icon-clock:before {
  content: "\e941";
}
.cca-icon-cloud-arrow-up:before {
  content: "\e942";
}
.cca-icon-coachmark:before {
  content: "\e943";
}
.cca-icon-code-commit:before {
  content: "\e944";
}
.cca-icon-columns:before {
  content: "\e945";
}
.cca-icon-columns-2:before {
  content: "\e946";
}
.cca-icon-columns-applied:before {
  content: "\e947";
}
.cca-icon-comment-dollar:before {
  content: "\e948";
}
.cca-icon-comment-euro:before {
  content: "\e949";
}
.cca-icon-comment-question:before {
  content: "\e94a";
}
.cca-icon-comments-question:before {
  content: "\e94b";
}
.cca-icon-container-storage:before {
  content: "\e94c";
}
.cca-icon-copy:before {
  content: "\e94d";
}
.cca-icon-crop-and-rotate:before {
  content: "\e94e";
}
.cca-icon-cube:before {
  content: "\e94f";
}
.cca-icon-date-time-picker:before {
  content: "\e950";
}
.cca-icon-dollar-sign:before {
  content: "\e951";
}
.cca-icon-dolly-flatbed-empty:before {
  content: "\e952";
}
.cca-icon-dot-circle:before {
  content: "\e953";
}
.cca-icon-droplet:before {
  content: "\e954";
}
.cca-icon-duplicate:before {
  content: "\e955";
}
.cca-icon-ellipsis-vertical:before {
  content: "\e956";
}
.cca-icon-enterprise:before {
  content: "\e957";
}
.cca-icon-envelope-open-euro:before {
  content: "\e958";
}
.cca-icon-euro-sign:before {
  content: "\e959";
}
.cca-icon-exclamation:before {
  content: "\e95a";
}
.cca-icon-expand:before {
  content: "\e95b";
}
.cca-icon-export:before {
  content: "\e95c";
}
.cca-icon-external-link:before {
  content: "\e95d";
}
.cca-icon-eye:before {
  content: "\e95e";
}
.cca-icon-eye-slash:before {
  content: "\e95f";
}
.cca-icon-file-certificate:before {
  content: "\e960";
}
.cca-icon-file-check:before {
  content: "\e961";
}
.cca-icon-file-exclamation:before {
  content: "\e962";
}
.cca-icon-file-image:before {
  content: "\e963";
}
.cca-icon-file-image-1:before {
  content: "\e964";
}
.cca-icon-file-invoice:before {
  content: "\e965";
}
.cca-icon-file-invoice-dollar:before {
  content: "\e966";
}
.cca-icon-file-lines:before {
  content: "\e967";
}
.cca-icon-files:before {
  content: "\e968";
}
.cca-icon-file-xmark:before {
  content: "\e969";
}
.cca-icon-filter:before {
  content: "\e96a";
}
.cca-icon-filter-circle-applied:before {
  content: "\e96b";
}
.cca-icon-filter-circle-xmark:before {
  content: "\e96c";
}
.cca-icon-first-page:before {
  content: "\e96d";
}
.cca-icon-flash-on:before {
  content: "\e96e";
}
.cca-icon-floppy-disk:before {
  content: "\e96f";
}
.cca-icon-folder:before {
  content: "\e970";
}
.cca-icon-folder-open:before {
  content: "\e971";
}
.cca-icon-forklift:before {
  content: "\e972";
}
.cca-icon-fullscreen:before {
  content: "\e973";
}
.cca-icon-gas-pump:before {
  content: "\e974";
}
.cca-icon-gauge-low:before {
  content: "\e975";
}
.cca-icon-Gear-Settings:before {
  content: "\e976";
}
.cca-icon-grid-view:before {
  content: "\e977";
}
.cca-icon-group:before {
  content: "\e978";
}
.cca-icon-hand-holding-seedling:before {
  content: "\e979";
}
.cca-icon-hashtag:before {
  content: "\e97a";
}
.cca-icon-help-circle:before {
  content: "\e97b";
}
.cca-icon-high-value:before {
  content: "\e9e0";
}
.cca-icon-history:before {
  content: "\e97c";
}
.cca-icon-home:before {
  content: "\e97d";
}
.cca-icon-hourglass:before {
  content: "\e97e";
}
.cca-icon-info:before {
  content: "\e97f";
}
.cca-icon-internal:before {
  content: "\e980";
}
.cca-icon-invoice:before {
  content: "\e981";
}
.cca-icon-invoice-euro:before {
  content: "\e982";
}
.cca-icon-ios-face-id:before {
  content: "\e983";
}
.cca-icon-ios-touch-id:before {
  content: "\e984";
}
.cca-icon-Italic:before {
  content: "\e985";
}
.cca-icon-key:before {
  content: "\e986";
}
.cca-icon-keyboard:before {
  content: "\e987";
}
.cca-icon-last-page:before {
  content: "\e988";
}
.cca-icon-leaf:before {
  content: "\e989";
}
.cca-icon-link:before {
  content: "\e98a";
}
.cca-icon-list:before {
  content: "\e98b";
}
.cca-icon-list-view:before {
  content: "\e98c";
}
.cca-icon-loading:before {
  content: "\e98d";
}
.cca-icon-location-dot:before {
  content: "\e98e";
}
.cca-icon-location-xmark:before {
  content: "\e98f";
}
.cca-icon-lock:before {
  content: "\e990";
}
.cca-icon-mail:before {
  content: "\e991";
}
.cca-icon-mail-add-circle:before {
  content: "\e992";
}
.cca-icon-map-location-dot:before {
  content: "\e993";
}
.cca-icon-memo:before {
  content: "\e994";
}
.cca-icon-memo-circle-check:before {
  content: "\e995";
}
.cca-icon-menu:before {
  content: "\e996";
}
.cca-icon-messages:before {
  content: "\e997";
}
.cca-icon-messages-dollar:before {
  content: "\e998";
}
.cca-icon-messages-euro:before {
  content: "\e999";
}
.cca-icon-microchip-ai:before {
  content: "\e99a";
}
.cca-icon-minus:before {
  content: "\e99b";
}
.cca-icon-money-bill:before {
  content: "\e99c";
}
.cca-icon-no-more-task:before {
  content: "\e99d";
}
.cca-icon-octagon-xmark:before {
  content: "\e99e";
}
.cca-icon-orderedList:before {
  content: "\e99f";
}
.cca-icon-pallet-box:before {
  content: "\e9a0";
}
.cca-icon-paper-plane:before {
  content: "\e9a1";
}
.cca-icon-pause:before {
  content: "\e9a2";
}
.cca-icon-pencil:before {
  content: "\e9a3";
}
.cca-icon-pen-to-square:before {
  content: "\e9a4";
}
.cca-icon-percent:before {
  content: "\e9a5";
}
.cca-icon-person-carry:before {
  content: "\e9a6";
}
.cca-icon-person-dolly-empty:before {
  content: "\e9a7";
}
.cca-icon-phone:before {
  content: "\e9a8";
}
.cca-icon-phonebook:before {
  content: "\e9a9";
}
.cca-icon-pinned:before {
  content: "\e9aa";
}
.cca-icon-pinned-no:before {
  content: "\e9ab";
}
.cca-icon-pinned-yes:before {
  content: "\e9ac";
}
.cca-icon-placeholder:before {
  content: "\e9ad";
}
.cca-icon-plus:before {
  content: "\e9ae";
}
.cca-icon-polygon:before {
  content: "\e9af";
}
.cca-icon-pound-sign:before {
  content: "\e9b0";
}
.cca-icon-power:before {
  content: "\e9b1";
}
.cca-icon-receipt:before {
  content: "\e9b2";
}
.cca-icon-refresh:before {
  content: "\e9b3";
}
.cca-icon-request:before {
  content: "\e9b4";
}
.cca-icon-road:before {
  content: "\e9b5";
}
.cca-icon-rotate:before {
  content: "\e9b6";
}
.cca-icon-rotate-camera:before {
  content: "\e9b7";
}
.cca-icon-route:before {
  content: "\e9b8";
}
.cca-icon-scale-balanced:before {
  content: "\e9b9";
}
.cca-icon-search:before {
  content: "\e9ba";
}
.cca-icon-share:before {
  content: "\e9bb";
}
.cca-icon-simple-map:before {
  content: "\e9bc";
}
.cca-icon-smog:before {
  content: "\e9bd";
}
.cca-icon-sort:before {
  content: "\e9be";
}
.cca-icon-steering-wheel:before {
  content: "\e9bf";
}
.cca-icon-stopwatch:before {
  content: "\e9c0";
}
.cca-icon-story:before {
  content: "\e9c1";
}
.cca-icon-tags:before {
  content: "\e9c2";
}
.cca-icon-tail-lift:before {
  content: "\e9c3";
}
.cca-icon-taskboard:before {
  content: "\e9c4";
}
.cca-icon-temperature-3:before {
  content: "\e9c5";
}
.cca-icon-trailer:before {
  content: "\e9c6";
}
.cca-icon-translation:before {
  content: "\e9c7";
}
.cca-icon-trash-can:before {
  content: "\e9c8";
}
.cca-icon-tree:before {
  content: "\e9c9";
}
.cca-icon-truck:before {
  content: "\e9ca";
}
.cca-icon-truck-clock:before {
  content: "\e9cb";
}
.cca-icon-truck-fast:before {
  content: "\e9cc";
}
.cca-icon-truck-pickup:before {
  content: "\e9cd";
}
.cca-icon-truck-ramp:before {
  content: "\e9ce";
}
.cca-icon-truck-ramp-box:before {
  content: "\e9cf";
}
.cca-icon-truck-xmark:before {
  content: "\e9d0";
}
.cca-icon-Underline:before {
  content: "\e9d1";
}
.cca-icon-unorderedList:before {
  content: "\e9d2";
}
.cca-icon-upload:before {
  content: "\e9d3";
}
.cca-icon-user:before {
  content: "\e9d4";
}
.cca-icon-user-minus:before {
  content: "\e9d5";
}
.cca-icon-user-pen:before {
  content: "\e9d6";
}
.cca-icon-user-plus:before {
  content: "\e9d7";
}
.cca-icon-users:before {
  content: "\e9d8";
}
.cca-icon-user-slash:before {
  content: "\e9d9";
}
.cca-icon-users-slash:before {
  content: "\e9da";
}
.cca-icon-user-xmark:before {
  content: "\e9db";
}
.cca-icon-vessel:before {
  content: "\e9dc";
}
.cca-icon-warning:before {
  content: "\e9dd";
}
.cca-icon-weight-hanging:before {
  content: "\e9de";
}
.cca-icon-xmark:before {
  content: "\e9df";
}
.cca-icon-cross-dock:before {
  content: "\e9e1";
}
.cca-icon-train:before {
  content: "\e9e2";
}