@import 'tippy.js/dist/tippy.css';
@import 'tippy.js/themes/light.css';
@import 'tippy.js/dist/svg-arrow.css';
.tippy-box {
  @apply text-neutral-invert;
}
.cca-tippy {
  @apply border border-solid border-brand-light;
  @apply surface-neutral-darkest #{!important};
  .tippy-arrow {
    color: var(--surface-neutral-darkest);
  }
}

.cca-popover-tippy {
  @apply rounded-xl surface-neutral-light #{!important};
  .tippy-content {
    @apply p-0 #{!important};
  }
  .tippy-arrow:before {
    border-bottom-color: var(--surface-neutral-light) !important;
  }
}

.tippy-box {
  box-shadow:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px var(--surface-neutral-default) !important;
}
