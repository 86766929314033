mat-button-toggle {
  --mat-standard-button-toggle-height: 3rem;
  margin-left: -1px;
  border-left: 0;

  &:first-child {
    margin-left: 0;
    @apply rounded-l-lg;
  }

  &:last-child {
    @apply rounded-r-lg;
  }

  &.white.mat-button-toggle-appearance-standard
    .mat-button-toggle-label-content {
    @apply text-neutral-invert;
  }

  &.mat-button-toggle-appearance-standard {
    .mat-button-toggle-label-content {
      @apply px-6 font-medium text-neutral-body;
    }
  }

  &.mat-button-toggle.mat-button-toggle-checked {
    z-index: 1;
    @apply surface-brand-default;
    @apply text-neutral-invert #{!important};
    span {
      @apply text-neutral-invert #{!important};
    }
  }

  .mat-pseudo-checkbox {
    display: none;
  }
}

.mat-button-toggle-group-appearance-standard {
  --mat-standard-button-toggle-shape: var(--mdc-shape-small);
}
