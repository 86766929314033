cca-icon {
  display: inline-flex;
}

table cca-icon.text-base,
mat-table cca-icon.text-base {
  line-height: 1.25rem;
}

.mat-icon.mat-icon-inline {
  overflow: initial !important;
}

.mat-mdc-icon-button {
  --mdc-icon-button-icon-size: 1.5rem;
}
