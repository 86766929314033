/*
 * Base layer
 */
@tailwind base;

/**
  * Components layer
  */
@tailwind components;

/**
  * Utilities layer
  */
@tailwind utilities;

@layer base {
  body,
  html {
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    background-color: var(--surface-neutral-default) !important;
  }
}

:root {
  /* Colors - Neutrals */
  --color-neutrals-50: #ffffff; /* White */
  --color-neutrals-100: #fafafa;
  --color-neutrals-200: #ebebeb;
  --color-neutrals-300: #d9d9d9;
  --color-neutrals-400: #b3b3b3;
  --color-neutrals-500: #8b8b8b;
  --color-neutrals-600: #717171;
  --color-neutrals-700: #565656;
  --color-neutrals-800: #3e3e3e;
  --color-neutrals-900: #333333; /* #272727 old Black */

  /* Colors - Green */
  --color-green-50: #f6fbe9;
  --color-green-100: #e3f2c4;
  --color-green-200: #d5eba9;
  --color-green-300: #b7d47f;
  --color-green-400: #a7c46c;
  --color-green-500: #8dab51;
  --color-green-600: #6f8f2f;
  --color-green-700: #638029;
  --color-green-800: #4b6615;
  --color-green-900: #3d5213;
  --color-green-950: #343630;
  --color-green-1000: #282925;

  /* Colors - Purple */
  --color-purple-50: #efecf8;
  --color-purple-100: #c6bae7;
  --color-purple-200: #b3a3de;
  --color-purple-300: #9984d3;
  --color-purple-400: #775bc5;
  --color-purple-500: #53389e;
  --color-purple-600: #473086;
  --color-purple-700: #3c2872;
  --color-purple-800: #332261;
  --color-purple-900: #2b1d53;

  /* Colors - Orange */
  --color-orange-50: #fcf0de;
  --color-orange-100: #fadfba;
  --color-orange-200: #f7c988;
  --color-orange-300: #f5b153;
  --color-orange-400: #f4a12d;
  --color-orange-500: #f5930b;
  --color-orange-600: #eb8900;
  --color-orange-700: #e08300;
  --color-orange-800: #b25900;
  --color-orange-900: #804000;

  /* Colors - Red */
  --color-red-50: #f9e1e1;
  --color-red-100: #f3adad;
  --color-red-200: #ef9292;
  --color-red-300: #e96e6e;
  --color-red-400: #e23e3e;
  --color-red-500: #b91c1c;
  --color-red-600: #9d1818;
  --color-red-700: #861414;
  --color-red-800: #721111;
  --color-red-900: #610f0f;

  /* Colors - Bluegreen */
  --color-bluegreen-50: #e3f8f1;
  --color-bluegreen-100: #c4f2e3;
  --color-bluegreen-200: #a0e8d0;
  --color-bluegreen-300: #7fdbbd;
  --color-bluegreen-400: #52d1a7;
  --color-bluegreen-500: #3bc497;
  --color-bluegreen-600: #2db286;
  --color-bluegreen-700: #1b966d;
  --color-bluegreen-800: #0b7d57;
  --color-bluegreen-900: #0d6648;

  /* Colors - Blue */
  --color-blue-50: #e0f1fa;
  --color-blue-100: #b0def7;
  --color-blue-200: #95ceed;
  --color-blue-300: #6fbae3;
  --color-blue-400: #43a4d9;
  --color-blue-500: #2e85b4;
  --color-blue-600: #2078a8;
  --color-blue-700: #196791;
  --color-blue-800: #0f5880;
  --color-blue-900: #094463;

  /* Colors - Newcold-1 */
  --color-newcold-1-50: #f8faff;
  --color-newcold-1-100: #e4edff;
  --color-newcold-1-200: #c1d6ff;
  --color-newcold-1-300: #9dc0fa;
  --color-newcold-1-400: #085bff;
  --color-newcold-1-500: #003aae;
  --color-newcold-1-600: #001847;
  --color-newcold-1-700: #000b20;
  --color-newcold-1-800: #000308;
  --color-newcold-1-900: #000000;

  /* Colors - Newcold-2 */
  --color-newcold-2-50: #fafdff;
  --color-newcold-2-100: #ecf6ff;
  --color-newcold-2-200: #d4ecff;
  --color-newcold-2-300: #b3ddff;
  --color-newcold-2-400: #53b2ff;
  --color-newcold-2-500: #1596ff;
  --color-newcold-2-600: #0070cc;
  --color-newcold-2-700: #004177;
  --color-newcold-2-800: #002544;
  --color-newcold-2-900: #001c33;

  --grayscale-mode-neutral: var(--color-neutrals-800); /* Neutrals.800 */

  /* Neutral Colors */
  --surface-neutral-disabled: var(--color-neutrals-400); /* Neutrals.400 */
  --surface-neutral-darkest: var(--color-neutrals-800); /* Neutrals.800 */
  --surface-neutral-darker: var(--color-neutrals-300); /* Neutrals.300 */
  --surface-neutral-dark: var(--color-neutrals-200); /* Neutrals.200 */
  --surface-neutral-default: var(--color-neutrals-100); /* Neutrals.100 */
  --surface-neutral-light: var(--color-neutrals-50); /* Neutrals.50 */
  --border-neutral-disabled: var(--color-neutrals-400); /* Neutrals.400 */
  --border-neutral-invert: var(--color-neutrals-50); /* Neutrals.50 */
  --border-neutral-darker: var(--color-neutrals-800); /* Neutrals.800 */
  --border-neutral-dark: var(--color-neutrals-500); /* Neutrals.500 */
  --border-neutral-default: var(--color-neutrals-300); /* Neutrals.300 */
  --text-neutral-invert: var(--color-neutrals-50); /* Neutrals.50 */
  --text-neutral-title: var(--color-neutrals-800); /* Neutrals.800 */
  --text-neutral-subtitle: var(--color-neutrals-600); /* Neutrals.600 */
  --text-neutral-body: var(--color-neutrals-800); /* Neutrals.800 */
  --text-neutral-caption: var(--color-neutrals-500); /* Neutrals.500 */
  --text-neutral-disabled: var(--color-neutrals-400); /* Neutrals.400 */

  /* CCA Brand Colors */
  --surface-brand-default: var(--color-green-600); /* Green.600 */
  --surface-brand-dark: var(--color-green-700); /* Green.700 */
  --surface-brand-darker: var(--color-green-800); /* Green.800 */
  --surface-brand-light: var(--color-green-200); /* Green.200 */
  --surface-brand-lighter: var(--color-green-100); /* Green.100 */
  --surface-brand-lightest: var(--color-green-50); /* Green.50 */
  --border-brand-default: var(--color-green-600); /* Green.600 */
  --border-brand-light: var(--color-green-400); /* Green.400 */
  --border-brand-lightest: var(--color-green-200); /* Green.200 */
  --text-brand-default: var(--color-green-600); /* Green.600 */
  --text-brand-dark: var(--color-green-700); /* Green.700 */
  --text-brand-darker: var(--color-green-800); /* Green.800 */
  --text-brand-light: var(--color-green-400); /* Green.400 */
  --text-brand-lighter: var(--color-green-200); /* Green.200 */

  /* Semantic Colors - Info */
  --info-surface: var(--color-purple-400); /* Purple.400 */
  --info-surface-light: var(--color-purple-100); /* Purple.100 */
  --info-surface-lighter: var(--color-purple-100); /* Purple.100 */
  --info-surface-lightest: var(--color-purple-50); /* Purple.50 */
  --info-surface-dark: var(--color-purple-500); /* Purple.500 */
  --info-surface-darker: var(--color-purple-600); /* Purple.600 */
  --info-border: var(--color-purple-400); /* Purple.400 */
  --info-border-light: var(--color-purple-300); /* Purple.300 */
  --info-border-lighter: var(--color-purple-100); /* Purple.100 */
  --info-border-dark: var(--color-purple-500); /* Purple.500 */
  --info-border-darker: var(--color-purple-800); /* Purple.800 */
  --info-text: var(--color-purple-500); /* Purple.500 */
  --info-text-light: var(--color-purple-400); /* Purple.400 */
  --info-text-lighter: var(--color-purple-300); /* Purple.300 */
  --info-text-dark: var(--color-purple-700); /* Purple.700 */
  --info-text-darker: var(--color-purple-800); /* Purple.800 */

  /* Semantic Colors - Success */
  --success-surface: var(--color-green-600); /* Green.600 */
  --success-surface-light: var(--color-green-100); /* Green.100 */
  --success-surface-lighter: var(--color-green-50); /* Green.50 */
  --success-surface-dark: var(--color-green-700); /* Green.700 */
  --success-surface-darker: var(--color-green-800); /* Green.800 */
  --success-border: var(--color-green-600); /* Green.600 */
  --success-border-light: var(--color-green-100); /* Green.100 */
  --success-border-dark: var(--color-green-700); /* Green.700 */
  --success-text: var(--color-green-800); /* Green.800 */
  --success-text-dark: var(--color-green-900); /* Green.900 */
  --success-border-darker: var(--color-green-800); /* Green.800 */

  /* Semantic Colors - Warning */
  --warning-surface: var(--color-orange-700); /* Orange.700 */
  --warning-surface-light: var(--color-orange-100); /* Orange.100 */
  --warning-surface-lighter: var(--color-orange-50); /* Orange.50 */
  --warning-surface-dark: var(--color-orange-800); /* Orange.800 */
  --warning-surface-darker: var(--color-orange-900); /* Orange.900 */
  --warning-border: var(--color-orange-700); /* Orange.700 */
  --warning-border-light: var(--color-orange-100); /* Orange.100 */
  --warning-border-lighter: var(--color-orange-50); /* Orange.50 */
  --warning-border-dark: var(--color-orange-800); /* Orange.800 */
  --warning-border-darker: var(--color-orange-900); /* Orange.900 */
  --warning-text: var(--color-orange-700); /* Orange.700 */
  --warning-text-light: var(--color-orange-50); /* Orange.50 */
  --warning-text-lighter: var(--color-orange-50); /* Orange.50 */
  --warning-text-dark: var(--color-orange-800); /* Orange.800 */
  --warning-text-darker: var(--color-orange-900); /* Orange.900 */

  /* Neutral */
  --neutral-surface: var(--color-neutrals-400); /* Neutrals.400 */
  --neutral-surface-light: var(--color-neutrals-200); /* Neutrals.200 */
  --neutral-surface-lighter: var(--color-neutrals-50); /* Neutrals.50 */
  --neutral-surface-dark: var(--color-neutrals-500); /* Neutrals.500 */
  --neutral-surface-darker: var(--color-neutrals-600); /* Neutrals.600 */

  --neutral-border: var(--color-neutrals-300); /* Neutrals.300 */
  --neutral-border-light: var(--color-neutrals-200); /* Neutrals.200 */
  --neutral-border-dark: var(--color-neutrals-500); /* Neutrals.500 */

  --neutral-text: var(--color-neutrals-800); /* Neutrals.800 */
  --neutral-text-dark: var(--color-neutrals-900); /* Neutrals.900 */

  /* Critical Colors */
  --critical-surface: var(--color-red-600); /* Red.600 */
  --critical-surface-light: var(--color-red-100); /* Red.100 */
  --critical-surface-lighter: var(--color-red-50); /* Red.50 */
  --critical-surface-dark: var(--color-red-700); /* Red.700 */
  --critical-surface-darker: var(--color-red-800); /* Red.800 */
  --critical-border: var(--color-red-500); /* Red.500 */
  --critical-border-light: var(--color-red-100); /* Red.100 */
  --critical-border-dark: var(--color-red-600); /* Red.600 */
  --critical-border-darker: var(--color-red-700); /* Red.700 */
  --critical-text: var(--color-red-700); /* Red.700 */
  --critical-text-light: var(--color-red-500); /* Red.500 */
  --critical-text-lightest: var(--color-red-50); /* Red.50 */
  --critical-text-dark: var(--color-red-800); /* Red.800 */

  /* Accent Colors */
  --accent-blue-lighter: var(--color-blue-50); /* Blue.50 */
  --accent-blue-light: var(--color-blue-100); /* Blue.100 */
  --accent-blue-default: var(--color-blue-500); /* Blue.500 */
  --accent-blue-dark: var(--color-blue-800); /* Blue.800 */
  --accent-blue-darker: var(--color-blue-900); /* Blue.900 */

  /* Checkbox and Radio Colors */
  --selection-tick: var(--color-neutrals-50); /* Neutrals.50 */
  --selection-enabled: var(--color-green-600); /* Green.600 */
  --selection-disabled: var(--color-neutrals-300); /* Neutrals.300 */
  --selection-hover: var(--color-green-700); /* Green.700 */
  --selection-default: var(--color-neutrals-400); /* Neutrals.400 */
  --selection-state: var(--color-green-50); /* Green.50 */

  /* Form Colors */
  --form-value: var(--color-neutrals-800); /* Neutrals.800 */
  --form-cursor: var(--color-green-500); /* Green.500 */
  --placeholder: var(--color-neutrals-300); /* Neutrals.300 */
  --form-disabled: var(--color-neutrals-300); /* Neutrals.300 */

  /* Form Surface Colors */
  --form: var(--color-neutrals-50); /* Neutrals.50 */
  --form-hover: var(--color-neutrals-100); /* Neutrals.100 */
  --form-focus: var(--color-neutrals-50); /* Neutrals.50 */

  /* Form Border Colors */
  --form-border: var(--color-neutrals-400); /* Neutrals.400 */
  --form-border-disabled: var(--color-neutrals-300); /* Neutrals.300 */
  --form-border-focus: var(--color-green-500); /* Green.500 */
  --form-border-hover: var(--color-neutrals-800); /* Neutrals.800 */

  --form-icon: var(--color-neutrals-800); /* Neutrals.800 */

  /* Form Label Colors */
  --form-label-disabled: var(--color-neutrals-400); /* Neutrals.400 */
  --form-label-float: var(--color-neutrals-500); /* Neutrals.500 */
  --form-label-focus: var(--color-green-700); /* Green.700 */
  --form-label: var(--color-neutrals-700); /* Neutrals.700 */

  /* Form Required and Critical Colors */
  --form-required: var(--color-red-300); /* Red.300 */
  --form-critical: var(--color-red-600); /* Red.600 */
  --form-success: var(--color-green-700); /* Green.700 */

  /* Button Text Label Colors */
  --button-text-label: var(--color-green-600); /* Green.600 */
  --button-text-label-hover: var(--color-green-700); /* Green.700 */
  --button-text-label-focused: var(--color-green-700); /* Green.700 */
  --button-text-label-pressed: var(--color-green-800); /* Green.800 */
  --button-text-label-critical: var(--color-red-600); /* Red.600 */

  /* Button Text Surface Colors */
  --button-text-hover: var(--color-green-50); /* Green.50 */
  --button-text-focused: var(--color-green-100); /* Green.100 */
  --button-text-pressed: var(--color-green-50); /* Green.50 */

  /* Link Colors */
  --button-link-default: var(--color-green-600); /* Green.600 */
  --button-link-hovered: var(--color-green-700); /* Green.700 */
  --button-link-focused: var(--color-green-500); /* Green.500 */
  --button-link-pressed: var(--color-green-800); /* Green.800 */

  /* Border Colors */
  --button-subtle-border-default: var(--color-neutrals-300); /* Neutrals.300 */
  --button-subtle-border-hovered: var(--color-neutrals-500); /* Neutrals.500 */
  --button-subtle-border-focused: var(--color-neutrals-300); /* Neutrals.300 */
  --button-subtle-border-pressed: var(--color-neutrals-300); /* Neutrals.300 */

  /* Label Colors */
  --button-subtle-label-default: var(--color-neutrals-600); /* Neutrals.600 */
  --button-subtle-label-hovered: var(--color-neutrals-800); /* Neutrals.800 */
  --button-subtle-label-focused: var(--color-neutrals-800); /* Neutrals.800 */
  --button-subtle-label-pressed: var(--color-neutrals-800); /* Neutrals.800 */

  /* Surface Colors */
  --button-subtle-pressed: var(--color-neutrals-200); /* Neutrals.200 */
  --button-subtle-focused: var(--color-neutrals-200); /* Neutrals.200 */

  /* Surface Colors */
  --button-secondary-hovered: var(--color-green-50); /* Green.50 */
  --button-secondary-focused: var(--color-green-100); /* Green.100 */
  --button-secondary-pressed: var(--color-green-50); /* Green.50 */

  /* Label Colors */
  --button-secondary-label: var(--color-green-600); /* Green.600 */

  /* Border Colors */
  --button-secondary-border-default: var(--color-green-600); /* Green.600 */
  --button-secondary-border-hovered: var(--color-green-600); /* Green.600 */
  --button-secondary-border-focused: var(--color-green-400); /* Green.400 */
  --button-secondary-border-pressed: var(--color-green-600); /* Green.600 */

  /* Surface Colors */
  --button-primary-default: var(--color-green-600); /* Green.600 */
  --button-primary-hovered: var(--color-green-700); /* Green.700 */
  --button-primary-focused: var(--color-green-700); /* Green.700 */
  --button-primary-pressed: var(--color-green-800); /* Green.800 */

  /* Label Color */
  --button-primary-label: var(--color-green-50); /* Green.50 */

  /* Border Color */
  --button-primary-border-focused: var(--color-green-400); /* Green.400 */

  /* Segmented Button Styles */
  --segmented-label: var(--color-neutrals-800); /* Neutrals.800 */
  --segmented-hovered: var(--color-green-50); /* Green.50 */
  --segmented-focused: var(--color-green-100); /* Green.100 */
  --segmented-selected: var(--color-green-200); /* Green.200 */
  --segmented-disabled: var(--color-neutrals-400); /* Neutrals.400 */
  --segmented-border: var(--color-neutrals-300); /* Neutrals.300 */
  --segmented-border-hover: var(--color-neutrals-500); /* Neutrals.500 */
  --segmented-border-focus: var(--color-green-500); /* Green.500 */
  --segmented-border-selected: var(--color-neutrals-500); /* Neutrals.500 */

  /* Disabled Button Style */
  --button-disabled: var(--color-neutrals-400); /* Neutrals.400 */
}

.dark {
  /* Surface */
  --surface-neutral-disabled: var(--color-neutrals-700); /* Neutrals.700 */
  --surface-neutral-darkest: var(--color-neutrals-300); /* Neutrals.300 */
  --surface-neutral-darker: var(--color-neutrals-700); /* Neutrals.700 */
  --surface-neutral-dark: var(--color-neutrals-800); /* Neutrals.800 */
  --surface-neutral-default: var(--color-neutrals-900); /* Neutrals.900 */
  --surface-neutral-light: var(--color-neutrals-800); /* Neutrals.800 */

  /* Border */
  --border-neutral-disabled: var(--color-neutrals-700); /* Neutrals.700 */
  --border-neutral-invert: var(--color-neutrals-800); /* Neutrals.800 */
  --border-neutral-darker: var(--color-neutrals-400); /* Neutrals.400 */
  --border-neutral-dark: var(--color-neutrals-500); /* Neutrals.500 */
  --border-neutral-default: var(--color-neutrals-700); /* Neutrals.700 */

  /* Text */
  --text-neutral-invert: var(--color-neutrals-800); /* Neutrals.800 */
  --text-neutral-title: var(--color-neutrals-100); /* Neutrals.100 */
  --text-neutral-subtitle: var(--color-neutrals-300); /* Neutrals.300 */
  --text-neutral-body: var(--color-neutrals-200); /* Neutrals.200 */
  --text-neutral-caption: var(--color-neutrals-400); /* Neutrals.400 */
  --text-neutral-disabled: var(--color-neutrals-700); /* Neutrals.700 */

  /* CCA_Brand */

  /* Surface */
  --surface-brand-default: var(--color-green-300); /* Green.300 */
  --surface-brand-dark: var(--color-green-200); /* Green.200 */
  --surface-brand-darker: var(--color-green-100); /* Green.100 */
  --surface-brand-light: var(--color-green-700); /* Green.700 */
  --surface-brand-lighter: var(--color-green-800); /* Green.800 */
  --surface-brand-lightest: var(--color-green-900); /* Green.900 */

  /* Border */
  --border-brand-default: var(--color-green-300); /* Green.300 */
  --border-brand-light: var(--color-green-700); /* Green.700 */
  --border-brand-lightest: var(--color-green-900); /* Green.900 */

  /* Text */
  --text-brand-default: var(--color-green-300); /* Green.300 */
  --text-brand-dark: var(--color-green-200); /* Green.200 */
  --text-brand-darker: var(--color-green-100); /* Green.100 */
  --text-brand-light: var(--color-green-800); /* Green.800 */
  --text-brand-lighter: var(--color-green-900); /* Green.900 */

  /* Semantic */

  /* Info */
  --info-surface: var(--color-purple-400); /* Purple.400 */
  --info-surface-light: var(--color-purple-200); /* Purple.200 */
  --info-surface-lighter: var(--color-purple-100); /* Purple.100 */
  --info-surface-lightest: var(--color-purple-50); /* Purple.50 */
  --info-surface-dark: var(--color-purple-500); /* Purple.500 */
  --info-surface-darker: var(--color-purple-600); /* Purple.600 */

  --info-border: var(--color-purple-400); /* Purple.400 */
  --info-border-light: var(--color-purple-300); /* Purple.300 */
  --info-border-lighter: var(--color-purple-100); /* Purple.100 */
  --info-border-dark: var(--color-purple-500); /* Purple.500 */
  --info-border-darker: var(--color-purple-800); /* Purple.800 */

  --info-text: var(--color-purple-300); /* Purple.300 */
  --info-text-light: var(--color-purple-400); /* Purple.400 */
  --info-text-lighter: var(--color-purple-300); /* Purple.300 */
  --info-text-dark: var(--color-purple-700); /* Purple.700 */
  --info-text-darker: var(--color-purple-800); /* Purple.800 */

  /* Success */
  --success-surface: var(--color-green-300); /* Green.300 */
  --success-surface-light: var(--color-green-200); /* Green.200 */
  --success-surface-lighter: var(--color-green-100); /* Green.100 */
  --success-surface-dark: var(--color-green-400); /* Green.400 */
  --success-surface-darker: var(--color-green-600); /* Green.600 */

  --success-border: var(--color-green-300); /* Green.300 */
  --success-border-light: var(--color-green-200); /* Green.200 */
  --success-border-dark: var(--color-green-400); /* Green.400 */
  --success-border-darker: var(--color-green-600); /* Green.600 */
  --success-text: var(--color-green-900); /* Green.900 */
  --success-text-dark: var(--color-green-800); /* Green.800 */

  /* Warning */
  --warning-surface: var(--color-orange-300); /* Orange.300 */
  --warning-surface-light: var(--color-orange-200); /* Orange.200 */
  --warning-surface-lighter: var(--color-orange-100); /* Orange.100 */
  --warning-surface-dark: var(--color-orange-600); /* Orange.600 */
  --warning-surface-darker: var(--color-orange-700); /* Orange.700 */

  --warning-border: var(--color-orange-400); /* Orange.400 */
  --warning-border-light: var(--color-orange-200); /* Orange.200 */
  --warning-border-lighter: var(--color-orange-100); /* Orange.100 */
  --warning-border-dark: var(--color-orange-600); /* Orange.600 */
  --warning-border-darker: var(--color-orange-700); /* Orange.700 */

  --warning-text: var(--color-orange-700); /* Orange.700 */
  --warning-text-light: var(--color-orange-200); /* Orange.200 */
  --warning-text-lighter: var(--color-orange-100); /* Orange.100 */
  --warning-text-dark: var(--color-orange-800); /* Orange.800 */
  --warning-text-darker: var(--color-orange-900); /* Orange.900 */

  /* Neutral */
  --neutral-surface: var(--color-neutrals-400); /* Neutrals.400 */
  --neutral-surface-light: var(--color-neutrals-200); /* Neutrals.200 */
  --neutral-surface-lighter: var(--color-neutrals-50); /* Neutrals.50 */
  --neutral-surface-dark: var(--color-neutrals-500); /* Neutrals.500 */
  --neutral-surface-darker: var(--color-neutrals-600); /* Neutrals.600 */

  --neutral-border: var(--color-neutrals-300); /* Neutrals.300 */
  --neutral-border-light: var(--color-neutrals-200); /* Neutrals.200 */
  --neutral-border-dark: var(--color-neutrals-500); /* Neutrals.500 */

  --neutral-text: var(--color-neutrals-800); /* Neutrals.800 */
  --neutral-text-dark: var(--color-neutrals-900); /* Neutrals.900 */

  /* Critical */
  --critical-surface: var(--color-red-200); /* Red.200 */
  --critical-surface-light: var(--color-red-100); /* Red.100 */
  --critical-surface-lighter: var(--color-red-50); /* Red.50 */
  --critical-surface-dark: var(--color-red-400); /* Red.400 */
  --critical-surface-darker: var(--color-red-300); /* Red.300 */

  --critical-border: var(--color-red-500); /* Red.500 */
  --critical-border-light: var(--color-red-100); /* Red.100 */
  --critical-border-dark: var(--color-red-600); /* Red.600 */
  --critical-border-darker: var(--color-red-700); /* Red.700 */

  --critical-text: var(--color-red-800); /* Red.800 */
  --critical-text-light: var(--color-red-400); /* Red.400 */
  --critical-text-lightest: var(--color-red-800); /* Red.800 */
  --critical-text-dark: var(--color-red-100); /* Red.100 */

  /* Accent */
  --accent-blue-lighter: var(--color-blue-50); /* Blue.50 */
  --accent-blue-light: var(--color-blue-100); /* Blue.100 */
  --accent-blue-default: var(--color-blue-500); /* Blue.500 */
  --accent-blue-dark: var(--color-blue-800); /* Blue.800 */
  --accent-blue-darker: var(--color-blue-900); /* Blue.900 */

  /* Checkbox-Radio */
  --selection-tick: var(--color-neutrals-50); /* Neutrals.50 */
  --selection-enabled: var(--color-green-600); /* Green.600 */
  --selection-disabled: var(--color-neutrals-300); /* Neutrals.300 */
  --selection-hover: var(--color-green-700); /* Green.700 */
  --selection-default: var(--color-neutrals-400); /* Neutrals.400 */
  --selection-state: var(--color-green-50); /* Green.50 */

  /* Form */
  --form-value: var(--color-neutrals-200); /* Neutrals.200 */
  --form-cursor: var(--color-purple-300); /* Purple.300 */
  --placeholder: var(--color-neutrals-600); /* Neutrals.600 */
  --form-disabled: var(--color-neutrals-600); /* Neutrals.600 */

  --form: var(--color-custom-900); /* Custom */
  --form-hover: var(--color-custom-900); /* Custom */
  --form-focus: var(--color-custom-800); /* Custom */

  --form-border: var(--color-neutrals-500); /* Neutrals.500 */
  --form-border-disabled: var(--color-neutrals-600); /* Neutrals.600 */
  --form-border-focus: var(--color-green-200); /* Green.200 */
  --form-border-hover: var(--color-neutrals-300); /* Neutrals.300 */

  --form-icon: var(--color-neutrals-600); /* Neutrals.600 */

  --form-label-disabled: var(--color-neutrals-600); /* Neutrals.600 */
  --form-label-float: var(--color-neutrals-300); /* Neutrals.300 */
  --form-label-focus: var(--color-green-200); /* Green.200 */
  --form-label: var(--color-neutrals-400); /* Neutrals.400 */

  --form-required: var(--color-red-300); /* Red.300 */
  --form-critical: var(--color-red-400); /* Red.400 */
  --form-success: var(--color-green-500); /* Green.500 */

  /* Buttons */

  /* Text - Label */
  --button-text-label: var(--color-green-100); /* Green.100 */
  --button-text-label-hover: var(--color-green-200); /* Green.200 */
  --button-text-label-focused: var(--color-green-200); /* Green.200 */
  --button-text-label-pressed: var(--color-green-100); /* Green.100 */

  /* Text - Surface */
  --button-text-hover: var(--color-green-800); /* Green.800 */
  --button-text-focused: var(--color-green-900); /* Green.900 */
  --button-text-pressed: var(--color-green-700); /* Green.700 */

  /* Link */
  --button-link-default: var(--color-green-100); /* Green.100 */
  --button-link-hovered: var(--color-green-200); /* Green.200 */
  --button-link-focused: var(--color-green-200); /* Green.200 */
  --button-link-pressed: var(--color-green-100); /* Green.100 */

  /* Subtle - Border */
  --button-subtle-border-default: var(--color-neutrals-700); /* Neutrals.700 */
  --button-subtle-border-hovered: var(--color-neutrals-500); /* Neutrals.500 */
  --button-subtle-border-focused: var(--color-neutrals-600); /* Neutrals.600 */
  --button-subtle-border-pressed: var(--color-neutrals-400); /* Neutrals.400 */

  /* Subtle - Label */
  --button-subtle-label-default: var(--color-neutrals-400); /* Neutrals.400 */
  --button-subtle-label-hovered: var(--color-neutrals-300); /* Neutrals.300 */
  --button-subtle-label-focused: var(--color-neutrals-200); /* Neutrals.200 */
  --button-subtle-label-pressed: var(--color-neutrals-100); /* Neutrals.100 */

  /* Subtle - Surface */
  --button-subtle-pressed: var(--color-neutrals-500); /* Neutrals.500 */
  --button-subtle-focused: var(--color-neutrals-700); /* Neutrals.700 */

  /* Secondary */
  --button-secondary-hovered: var(--color-green-900); /* Green.900 */
  --button-secondary-focused: var(--color-green-900); /* Green.900 */
  --button-secondary-pressed: var(--color-green-800); /* Green.800 */

  --button-secondary-label: var(--color-green-100); /* Green.100 */

  --button-secondary-border-default: var(--color-green-100); /* Green.100 */
  --button-secondary-border-hovered: var(--color-green-100); /* Green.100 */
  --button-secondary-border-focused: var(--color-green-200); /* Green.200 */
  --button-secondary-border-pressed: var(--color-green-100); /* Green.100 */

  /* Primary */
  --button-primary-default: var(--color-green-300); /* Green.300 */
  --button-primary-hovered: var(--color-green-400); /* Green.400 */
  --button-primary-focused: var(--color-green-400); /* Green.400 */
  --button-primary-pressed: var(--color-green-500); /* Green.500 */

  --button-primary-label: var(--color-neutrals-800); /* Neutrals.800 */

  --button-primary-border-focused: var(--color-green-200); /* Green.200 */

  /* Segmented */
  --segmented-label: var(--color-neutrals-800); /* Neutrals.800 */
  --segmented-hovered: var(--color-green-50); /* Green.50 */
  --segmented-focused: var(--color-green-100); /* Green.100 */
  --segmented-selected: var(--color-green-200); /* Green.200 */

  --segmented-disabled: var(--color-neutrals-400); /* Neutrals.400 */
  --segmented-border: var(--color-neutrals-300); /* Neutrals.300 */
  --segmented-border-hover: var(--color-neutrals-500); /* Neutrals.500 */
  --segmented-border-focus: var(--color-green-500); /* Green.500 */
  --segmented-border-selected: var(--color-neutrals-500); /* Neutrals.500 */

  /* Button Disabled */
  --button-disabled: var(--color-neutrals-700); /* Neutrals.700 */
}

.theme-newcold {
  /* CCA Brand Colors */
  --surface-brand-default: var(--color-newcold-1-600); /* NewCold 600 */
  --surface-brand-dark: var(--color-newcold-1-500); /* NewCold 500 */
  --surface-brand-darker: var(--color-newcold-1-700); /* NewCold 700 */
  --surface-brand-light: var(--color-newcold-1-300); /* NewCold 300 */
  --surface-brand-lighter: var(--color-newcold-1-200); /* NewCold 200 */
  --surface-brand-lightest: var(--color-newcold-1-100); /* NewCold 100 */

  --border-brand-default: var(--color-newcold-1-600); /* NewCold 600 */
  --border-brand-light: var(--color-newcold-1-300); /* NewCold 300 */
  --border-brand-lightest: var(--color-newcold-1-100); /* NewCold 100 */

  --text-brand-default: var(--color-newcold-1-600); /* NewCold 600 */
  --text-brand-dark: var(--color-newcold-1-500); /* NewCold 500 */
  --text-brand-darker: var(--color-newcold-1-700); /* NewCold 700 */
  --text-brand-light: var(--color-newcold-1-300); /* NewCold 300 */
  --text-brand-lighter: var(--color-newcold-1-200); /* NewCold 200 */

  /* Button Text Label Colors */
  --button-text-label: var(--color-newcold-2-600); /* NewCold 2 600 */
  --button-text-label-hover: var(--color-newcold-1-600); /* NewCold 600 */
  --button-text-label-focused: var(--color-newcold-1-300); /* NewCold 300 */
  --button-text-label-pressed: var(--color-newcold-1-600); /* NewCold 600 */

  /* Surface Colors */
  --button-primary-default: var(--color-newcold-2-600); /* NewCold 2 600 */
  --button-primary-hovered: var(--color-newcold-1-600); /* NewCold 600 */
  --button-primary-focused: var(--color-newcold-1-300); /* NewCold 300 */
  --button-primary-pressed: var(--color-newcold-1-600); /* NewCold 600 */

  /* Checkbox-Radio */
  --selection-tick: var(--color-neutrals-50); /* Neutrals.50 */
  --selection-enabled: var(--color-newcold-2-600); /* NewCold 2 600 */
  --selection-disabled: var(--color-neutrals-300); /* Neutrals.300 */
  --selection-hover: var(--color-newcold-1-600); /* NewCold 600 */
  --selection-default: var(--color-neutrals-400); /* Neutrals.400 */
  --selection-state: var(--color-green-50); /* Green.50 */
}
