mat-slide-toggle.mat-mdc-slide-toggle {
  --mdc-switch-track-width: 2.5rem;
  --mdc-switch-track-height: 1.5rem;
  --mdc-switch-track-shape: 10rem;
  --mdc-switch-handle-width: 1.125rem;
  --mdc-switch-handle-height: 1.125rem;
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms
    cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms
    cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-selected-track-outline-color: transparent;
  --mdc-switch-disabled-label-text-color: var(--form-disabled);
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
  --mdc-switch-handle-shape: 10px;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mdc-switch-handle-surface-color: var(--surface-neutral-light);
  --mdc-switch-selected-handle-color: var(--border-neutral-invert);
  --mat-switch-label-text-color: var(--text-neutral-body);
  --mdc-switch-selected-focus-track-color: var(--surface-brand-default);
  --mdc-switch-selected-hover-track-color: var(--surface-brand-default);
  --mdc-switch-selected-pressed-track-color: var(--surface-brand-default);
  --mdc-switch-selected-track-color: var(--surface-brand-default);
  --mdc-switch-unselected-track-color: var(--surface-neutral-dark);
  --mdc-switch-selected-focus-handle-color: var(--surface-brand-lighter);
  --mdc-switch-selected-hover-handle-color: var(--surface-brand-lighter);
  --mdc-switch-selected-pressed-handle-color: var(--surface-brand-lighter);
  --mdc-switch-selected-handle-color: var(--surface-neutral-light);
  --mdc-switch-unselected-focus-state-layer-color: var(--border-brand-light);
  --mdc-switch-unselected-focus-state-layer-color: var(
    --surface-neutral-disabled
  );
  --mdc-switch-unselected-pressed-state-layer-color: var(
    --surface-neutral-disabled
  );
  --mdc-switch-unselected-hover-state-layer-color: var(
    --surface-neutral-disabled
  );
  --mdc-switch-unselected-focus-handle-color: var(--surface-neutral-disabled);
  --mdc-switch-unselected-hover-handle-color: var(--surface-neutral-disabled);
  --mdc-switch-unselected-pressed-handle-color: var(--surface-neutral-disabled);
  --mdc-switch-unselected-handle-color: var(--text-neutral-subtitle);
  --mdc-switch-disabled-selected-handle-color: var(--border-neutral-invert);
  --mdc-switch-disabled-selected-track-color: var(--surface-neutral-disabled);
  --mdc-switch-disabled-unselected-handle-color: var(
    --surface-neutral-disabled
  );
  --mdc-switch-disabled-unselected-track-color: var(--border-neutral-invert);
  --mdc-switch-disabled-track-opacity: 1;
  --mat-switch-disabled-handle-opacity: 1;
  --mdc-switch-disabled-icon-opacity: 1;
  --mdc-switch-disabled-unselected-track-opacity: 1;
  --mat-switch-disabled-unselected-handle-opacity: 1;
  --mdc-switch-disabled-unselected-icon-opacity: 1;

  .mdc-form-field > label {
    padding-left: 0.75rem;
    font-size: 1rem;
  }
}

// Switches
.mdc-switch.mdc-switch--unselected {
  .mdc-switch__track {
    @apply border-[1.5px] border-neutral-dark;
  }
  &.mdc-switch--disabled {
    .mdc-switch__track {
      @apply border-[1.5px] border-neutral-disabled;
    }
  }
  .mdc-switch__handle {
    left: 0.376rem;
    --mat-switch-with-icon-handle-size: 0.75rem;
  }
}
.mdc-switch__handle-track {
  width: calc(100% - var(--mdc-switch-handle-width) - 3px) !important;
  .mdc-switch__handle {
    --mat-switch-with-icon-handle-size: 1.125rem;
    .mdc-switch__shadow {
      display: none;
    }
    .mdc-switch__icon {
      display: none;
    }
  }
}
