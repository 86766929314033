.mat-mdc-checkbox,
.mdc-checkbox {
  --mdc-checkbox-disabled-selected-checkmark-color: var(--text-neutral-invert);
  --mat-checkbox-disabled-label-color: var(--neutral-disabled);
  --mdc-checkbox-disabled-unselected-icon-color: var(--border-neutral-default);
  --mdc-checkbox-disabled-selected-icon-color: var(--border-neutral-default);
  --mdc-checkbox-selected-checkmark-color: var(--text-neutral-invert);
  --mat-checkbox-label-text-color: var(--text-neutral-body);
  --mdc-checkbox-unselected-icon-color: var(--selection-default);
  --mdc-checkbox-unselected-hover-icon-color: var(--form-border-hover);
  --mdc-checkbox-unselected-focus-icon-color: var(--form-border-hover);
  --mdc-checkbox-disabled-unselected-icon-color: var(--border-neutral-disabled);
  --mdc-checkbox-selected-focus-icon-color: var(--button-primary-default);
  --mdc-checkbox-selected-hover-icon-color: var(--button-primary-default);
  --mdc-checkbox-selected-icon-color: var(--button-primary-default);
  --mdc-checkbox-selected-pressed-icon-color: var(--button-primary-default);
  --mdc-checkbox-selected-focus-state-layer-color: var(
    --button-primary-default
  );
  --mdc-checkbox-selected-hover-state-layer-color: var(
    --button-primary-default
  );
  --mdc-checkbox-selected-pressed-state-layer-color: var(
    --button-primary-default
  );
  --mdc-checkbox-unselected-focus-state-layer-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-hover-state-layer-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-state-layer-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
}

// Custom styling for not material checkboxes
.custom-checkboxes {
  input[type='checkbox'] {
    position: relative;
    display: none;

    &:checked + label::after {
      content: '\e92d'; // check icon
      @apply surface-brand-default;
    }

    + label {
      width: 3rem;
      height: 3rem;
      display: inline-flex;
      cursor: pointer;
      position: relative;
      align-items: center;
      justify-content: center;

      &::after {
        opacity: 1;
        top: 50%;
        left: 50%;
        z-index: 0;
        content: '';
        display: flex;
        width: 1.25rem;
        color: white;
        height: 1.25rem;
        position: absolute;
        align-items: center;
        justify-content: center;
        border-radius: 0.188rem;
        font-family: CtrlChainIcons;
        transform: translate(-50%, -50%);
        @apply border-2 border-solid border-brand-default;
      }

      &::before {
        content: '';
        width: 2.5rem;
        height: 2.5rem;
        display: block;
        border-radius: 10rem;
      }

      &:hover {
        &::before {
          @apply surface-brand-lighter;
        }
      }
    }
  }
}

.mdc-checkbox__background,
.mat-pseudo-checkbox {
  border-radius: 0.1875rem;
}

.mat-mdc-select-panel
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
    [data-indeterminate='true']
  )
  ~ .mdc-checkbox__background {
  border-color: rgba(0, 0, 0, 0.54);
  background-color: transparent;
}
