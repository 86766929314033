// styling for menu panel
:root {
  --mat-menu-item-leading-spacing: 1rem;
  --mat-menu-item-label-text-tracking: 0.03125em;
  --mat-menu-container-shape: 0.75rem;
  --mat-menu-container-color: var(--surface-neutral-light);
  --mat-menu-item-label-text-color: var(--text-neutral-body);
}
.mat-mdc-menu-panel.mat-mdc-menu-panel {
  @apply text-neutral-title;
  min-width: 15.25rem !important;
  box-shadow: 0px 2px 4px 1px #00000033 !important;
}

.mat-mdc-menu-panel.mat-mdc-menu-panel .mat-mdc-menu-content {
  padding: 0.75rem 0;
}

.mat-mdc-menu-item:not([disabled]):hover,
.mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-mdc-menu-item-highlighted:not([disabled]) {
  @apply text-neutral-invert surface-brand-default #{!important};
}

.mat-mdc-menu-item:hover:not([disabled]) .mdc-list-item__primary-text,
.mat-mdc-menu-item.cdk-program-focused:not([disabled])
  .mdc-list-item__primary-text,
.mat-mdc-menu-item.cdk-program-focused:not([disabled])
  .mdc-list-item__primary-text,
.mat-mdc-menu-item.cdk-keyboard-focused:not([disabled])
  .mdc-list-item__primary-text,
.mat-mdc-menu-item-highlighted:not([disabled]) .mdc-list-item__primary-text {
  @apply text-neutral-invert;
  .mat-icon-no-color {
    @apply text-neutral-invert;
  }
}

.mat-mdc-menu-item-submenu-trigger .mat-mdc-menu-ripple::after {
  font-family: CtrlChainIcons;
  content: '\e932';
  @apply absolute right-4 top-0 flex h-full items-center;
}
.mat-mdc-menu-submenu-icon {
  display: none;
}

// add padding to menu in firefox because of different behavior scroll bars
@-moz-document url-prefix() {
  cca-navigation {
    @apply pr-4 #{!important};
  }

  .smallSideNav cca-navigation {
    @apply pr-2 #{!important};
  }
}
