:root {
  --mat-expansion-container-background-color: var(--surface-neutral-light);
  --mat-expansion-container-text-color: var(--text-neutral-body);
  --mat-expansion-header-text-color: var(--text-neutral-body);
}
.mat-expansion-panel {
  border-radius: 0.625rem !important;
  border: solid 1px var(--border-neutral-default);

  .mat-expansion-panel-header {
    padding: 0 1rem;
    height: 3rem;
    font-size: 1rem;
    flex-direction: row-reverse;

    &.mat-expanded {
      height: 3.5rem;
    }
    .mat-expansion-indicator {
      margin-right: 1.25rem;
      &::after {
        padding: 0;
        border: none;
        transform: none;
        content: '\e930'; // chevron-down icon
        font-size: 1.5rem;
        display: inline-block;
        font-family: CtrlChainIcons;
        @apply text-neutral-body;
      }
      svg {
        display: none;
      }
    }
  }

  .mat-expansion-panel-body {
    padding: 0 1rem 1rem;
  }
}
