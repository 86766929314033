cca-main {
  display: block;
  height: 100%;

  .mat-drawer-container {
    height: 100% !important;
  }

  &.smallSideNav .appContent {
    grid-template-columns: 4.5rem 1fr;
  }
  .appContent {
    display: grid;
    grid-template-columns: 16.5rem 1fr;
    grid-template-rows: auto auto 1fr;
    grid-template-areas: 'topbar topbar' 'sidenav header' 'sidenav page';
    min-height: 100%;
    max-height: 100%;
    height: 100%;

    cca-message-banner {
      grid-area: topbar;
      width: 100%;
    }

    cca-sidenav {
      grid-area: sidenav;

      cca-navigation {
        min-height: 0;
        min-width: 0;
      }
    }

    cca-header {
      grid-area: header;
    }

    .page {
      @apply p-4;
      grid-area: page;
      min-width: 0;
      min-height: 0;
      overflow: auto;
      height: 100%;
      min-height: 100%;
      max-width: 100%;
      position: relative;

      > *:not(router-outlet) {
        height: inherit;
      }

      &:has(cca-sequence) {
        @apply p-0;
      }

      &:has(cca-grid) {
        @apply p-0;
      }
    }
    .surface-neutral {
      @apply surface-neutral-darker;
    }
  }
}

.menu-bar {
  @apply flex flex-shrink-0 overflow-hidden;
  a {
    @apply flex h-12 w-full cursor-pointer items-center justify-center whitespace-nowrap border-y border-r px-4 uppercase surface-neutral-light border-neutral-default text-brand-default;
    &:last-child {
      @apply rounded-r-xl;
    }
    &:first-child {
      @apply rounded-l-xl border-l;
    }
    &.active {
      @apply text-neutral-invert surface-brand-default;
    }
  }
}

.word-break {
  word-break: break-word;
}

// sequence sticky header
.sequence-header {
  @apply relative z-20 border-b border-solid surface-neutral-light border-neutral-default;
  box-shadow: 0px 4px 10px 0px rgba(192, 192, 192, 0.25);
  header {
    @apply flex w-full items-center p-4 px-8;
  }
  button {
    @apply flex-shrink-0;
  }
}

// sequence sticky header
.sticky-header {
  @apply sticky -top-4 z-20 -mx-4 -mt-4 border-b border-solid surface-neutral-light border-neutral-default;
  box-shadow: 0px 4px 10px 0px rgba(192, 192, 192, 0.25);
  header {
    @apply flex w-full items-center p-4 px-8;
  }
  button {
    @apply flex-shrink-0;
  }
}

.dark .sticky-header,
.dark .sequence-header {
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25) !important;
}

.full-page {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  max-width: 100% !important;
  height: 100% !important;
  max-height: 100% !important;
  z-index: 1000 !important;
  background-color: var(--surface-neutral-light) !important;
}
