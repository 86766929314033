:root {
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-handle-color: var(--button-primary-default);
  --mdc-slider-focus-handle-color: var(--button-primary-default);
  --mdc-slider-hover-handle-color: var(--button-primary-default);
  --mdc-slider-active-track-color: var(--button-primary-default);
  --mdc-slider-inactive-track-color: var(--button-primary-default);
  --mdc-slider-with-tick-marks-inactive-container-color: var(
    --button-primary-default
  );
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-slider-ripple-color: var(--button-primary-default);
  --mat-slider-hover-state-layer-color: rgba(111, 143, 47, 0.05);
  --mat-slider-focus-state-layer-color: rgba(111, 143, 47, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
  --mdc-switch-state-layer-size: 0.75rem;
  .mat-mdc-slide-toggle .mdc-switch--unselected .mdc-switch__handle {
    --mat-switch-pressed-handle-size: 0.75rem !important;
  }
  .mat-mdc-slide-toggle .mdc-switch--selected .mdc-switch__handle {
    --mat-switch-pressed-handle-size: 1.125rem !important;
  }
}
