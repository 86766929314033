// address pac container
.pac-container {
  z-index: 9999;
  margin-top: 0.8rem;
  border-radius: 0.5rem;
  .pac-item {
    font-size: 1rem;
    font-family: Roboto;
    padding: 0.75rem 1rem;
    border: none;
    display: flex;
    align-items: center;
    .pac-item-query {
      font-size: 1rem;
    }
    .pac-icon {
      margin: 0 1.5rem 0 0 !important;
      padding: 0 !important;
      background: none;
      line-height: 1;
      display: flex;
      align-items: center;
      &::after {
        content: '\e98e'; // location-dot icon
        font-size: 1.25rem;
        display: inline-block;
        font-family: CtrlChainIcons;
        @apply text-neutral-caption;
      }
    }
  }
  &.pac-logo::after {
    margin: 0.75rem !important;
  }
}
