.mat-mdc-table {
  --mat-table-background-color: var(--surface-neutral-light);
  --mat-paginator-container-background-color: var(--surface-neutral-light);
  --mat-table-row-item-label-text-color: var(--text-neutral-body);
  --mat-table-row-item-outline-color: var(--border-neutral-default);
  --mat-paginator-container-text-color: var(--text-neutral-body);
  --mat-table-row-item-label-text-tracking: 0.0178571429em;

  .mdc-data-table__header-row {
    height: auto;
  }
}
mat-paginator {
  --mdc-icon-button-icon-color: var(--text-brand-default);
  --mdc-icon-button-disabled-icon-color: var(--button-disabled);
}
// MDC removed top and bottom padding on table cells
table .mdc-data-table__cell,
table .mdc-data-table__header-cell {
  padding: 0.875rem;
  line-height: initial;
}

table .mdc-data-table__header-cell.cdk-column-check {
  @apply py-0;
}

table .mdc-data-table__cell .mat-mdc-icon-button {
  @apply text-neutral-caption;
}

table .mdc-data-table__cell {
  vertical-align: top;
}

table.mat-mdc-table .mdc-data-table__row {
  height: auto;
}

.cca-data-table-header:empty + .table-container {
  @apply -mt-6;
}

table td {
  vertical-align: middle !important;
}

.mdc-data-table__header-cell {
  --mat-table-row-item-outline-color: var(--border-neutral-dark);
  @apply font-medium text-neutral-body;
}

.mdc-data-table__cell {
  @apply border-neutral-default;
}

.mat-mdc-tab-body .cca-data-table-buttons,
.actions-top-spacing .cca-data-table-buttons {
  margin-top: 1.25rem;
}

.mat-mdc-tab-body .page-container .cca-data-table-buttons {
  margin-top: 0;
}

mat-paginator.mat-mdc-paginator {
  @apply text-sm;

  .mat-mdc-paginator-range-label {
    margin: 0 3rem;
  }

  .mat-mdc-paginator-container {
    padding: 0;
  }
  .mat-mdc-paginator-page-size-select {
    @apply w-32;

    .mdc-text-field--outlined:not(.mdc-text-field--disabled)
      .mdc-notched-outline__leading,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled)
      .mdc-notched-outline__notch,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled)
      .mdc-notched-outline__trailing {
      @apply border-neutral-default;
    }

    .mat-mdc-select-min-line {
      font-size: 0.875rem;
    }

    .mat-mdc-select-arrow {
      width: 1rem;
      height: 1rem;
      &::after {
        font-size: 1rem;
      }
    }
  }

  .mat-mdc-icon-button {
    font-family: CtrlChainIcons;

    .mat-mdc-paginator-icon {
      display: none;
    }

    &.mat-mdc-paginator-navigation-first::before {
      content: '\e96d'; // first-page icon
      z-index: 1;
    }

    &.mat-mdc-paginator-navigation-previous::before {
      content: '\e931'; // chevron-left icon
      z-index: 1;
    }

    &.mat-mdc-paginator-navigation-next::before {
      content: '\e932'; // chevron-right icon
      z-index: 1;
    }

    &.mat-mdc-paginator-navigation-last::before {
      content: '\e988'; // last-page icon
      z-index: 1;
    }
  }

  .mat-mdc-paginator-page-size-label {
    margin-right: 1.5rem;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined
    .mat-mdc-form-field-infix {
    height: 1.75rem;
    display: flex;
    align-items: center;
  }
}

/******* gradient bar sticky columns *******/
.mat-mdc-table-sticky-border-elem-left {
  padding-right: 1.75rem !important;
  overflow: visible !important;
}

.mat-mdc-table-sticky-border-elem-left::before,
.sticky-gradient::before {
  width: 0.5rem;
  right: -7px;
  top: 0;
  position: absolute;
  height: 100%;
  display: block;
  opacity: 0.6;
  content: '';
  @apply border-l bg-gradient-to-r border-neutral-default;
  --tw-gradient-from: var(--surface-neutral-default);
  --tw-gradient-to: rgba(255, 255, 255, 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  @apply to-transparent;
  background-image: linear-gradient(
    to right,
    var(--surface-neutral-default),
    rgba(255, 255, 255, 0)
  );
}

// align text to the middle in tables
.table-align-middle td {
  @apply align-middle #{!important};
}

.table-align-middle td:has(.mat-mdc-icon-button) {
  padding: 0;
}

.table-align-middle td .mat-mdc-icon-button {
  margin-top: 0;
}

// add border bottom of table
.mdc-data-table__row:last-child .mdc-data-table__cell {
  border-bottom-width: 1px !important;
  border-bottom-style: solid !important;
  border-bottom-color: var(--border-neutral-default) !important;
}
