.trip-planner-distance {
  background: rgb(224 241 250 / var(--tw-bg-opacity)) !important;

  .b-buffer-label {
    text-wrap: wrap;
    font-size: 12px;
    font-weight: 500;
    width: 170px;
    transform: none !important;
    text-align: left !important;
    margin-inline-start: 0.25em;
  }
  i {
    font-size: 16px;
    transform: none !important;
    margin-inline-start: 0 !important;
  }
}
.b-gridbase.b-split .b-grid-splitter:not(.b-disabled) .b-grid-splitter-inner {
  background-color: var(--border-neutral-default) !important;
}
.b-grid-row,
.b-grid-cell,
.b-column-line {
  border-color: var(--border-neutral-default) !important;
}
.b-column-line-major {
  border-color: var(--border-neutral-invert) !important;
}
.b-grid-panel-body,
.b-grid-header-container {
  background-color: var(--surface-neutral-light) !important;
  .b-sch-timeaxiscolumn,
  .b-grid-header.b-resizing {
    background-color: var(--surface-neutral-light) !important;
  }
  .b-grid-header {
    color: var(--text-neutral-subtitle);
  }
}

.b-grid-header-container
  .b-sch-timeaxiscolumn
  .b-sch-header-timeaxis-cell:hover {
  background: var(--surface-neutral-default) !important;
}

.b-grid-panel-body .b-grid-header,
.b-grid-header-container .b-grid-header {
  border-radius: 0;
}
.b-grid-panel-body .b-grid-header,
.b-grid-header-container
  .b-grid-header[data-column-id='b-schedulerpro-1-col-1'] {
  border-inline-end-color: var(--border-neutral-default) !important;
}

.b-grid-panel-body .b-grid-header,
.b-grid-header-container
  .b-grid-header[data-column-id='b-schedulerpro-1-col-2'] {
  border-inline-end-color: transparent !important;
}

.b-timeranges-body-canvas .trip-planner-current-time.b-sch-timerange {
  label {
    @apply bottom-1 ml-1 rounded-[4rem] px-2 py-1 text-sm font-medium text-cca-blue-800 accent-blue-lighter #{!important};
  }
  @apply border-dashed border-cca-blue-100 #{!important};
}
.b-grid-splitter-inner {
  width: 0.15em !important;
}
.b-grid-splitter {
  flex: 0 0 0.15em !important;
  width: 0.15em !important;
}

.b-gridbase.b-split .b-grid-splitter-buttons {
  @apply h-8 w-8 #{!important};
}

.b-gridbase.b-split .b-grid-splitter-button-icon {
  @apply h-5 #{!important};
}

.b-gridbase.b-split
  .b-grid-splitter:not(.b-disabled)
  .b-grid-splitter-button-collapse:hover
  .b-grid-splitter-button-icon,
.b-gridbase.b-split
  .b-grid-splitter:not(.b-disabled)
  .b-grid-splitter-button-expand:hover
  .b-grid-splitter-button-icon {
  @apply fill-cca-green-600 #{!important};
}
.b-panel {
  @apply surface-neutral-darkest #{!important};
}
.b-tooltip {
  box-shadow: none !important;
  @apply rounded #{!important};
  @apply border border-solid surface-neutral-default text-neutral-caption border-brand-light #{!important};
  .b-panel-content {
    @apply m-0 p-2 text-sm text-white #{!important};
  }
}
.b-sch-header-timeaxis-cell {
  @apply text-neutral-subtitle #{!important};
  @apply text-sm #{!important};
  @apply font-normal #{!important};
  @apply py-0 #{!important};
}
.b-horizontaltimeaxis .b-sch-header-row {
  flex: 1 0 1.5em !important;
}
.b-grid-header {
  @apply px-0 #{!important};
  &[data-column-id='b-schedulerpro-1-col-2'] {
    @apply border-e-cca-neutrals-300 #{!important};
  }
  &[data-column-id='b-schedulerpro-1-col-1'] {
    @apply border-e-cca-neutrals-300 #{!important};
  }
}
.b-grid-header-container {
  @apply h-[3.375rem] #{!important};
  @apply rounded-tr-xl;
  @apply rounded-tl-xl;
}
.b-grid-header-scroller {
  @apply rounded-tr-xl;
}
.b-grid-header-container,
.b-sch-header-timeaxis-cell,
.b-grid-body-container {
  @apply border border-solid border-neutral-default;
}
.b-sch-header-timeaxis-cell {
  @apply border-t-0 #{!important};
  @apply border-neutral-default #{!important};
}
.b-virtual-scrollers {
  @apply border-s-[1px] #{!important};
  @apply border-e-[1px] #{!important};
  @apply border-b-[1px] #{!important};
  @apply border-s-cca-neutrals-300 #{!important};
  @apply border-e-cca-neutrals-300 #{!important};
  @apply border-neutral-default #{!important};
}
