:root {
  // Input field error
  --mat-form-field-error-text-color: var(--critical-text-light);
  --mdc-outlined-text-field-error-outline-color: var(--critical-text-light);
  --mdc-outlined-text-field-error-focus-outline-color: var(
    --critical-text-light
  );
  --mdc-outlined-text-field-error-hover-outline-color: var(
    --critical-text-light
  );
  --mdc-outlined-text-field-error-caret-color: var(--critical-text-light);
  --mdc-outlined-text-field-error-focus-label-text-color: var(
    --critical-text-light
  );
  --mdc-outlined-text-field-error-label-text-color: var(--critical-text-light);
  --mdc-outlined-text-field-error-hover-label-text-color: var(
    --critical-text-light
  );
  --mat-form-field-error-text-color: var(--critical-text-light);

  --mdc-filled-text-field-error-hover-label-text-color: var(
    --critical-text-light
  );
  --mdc-filled-text-field-error-focus-label-text-color: var(
    --critical-text-light
  );
  --mdc-filled-text-field-error-label-text-color: var(--critical-text-light);
  --mdc-filled-text-field-error-caret-color: var(--critical-text-light);
  --mdc-filled-text-field-error-active-indicator-color: var(
    --critical-text-light
  );
  --mdc-filled-text-field-error-focus-active-indicator-color: var(
    --critical-text-light
  );
  --mdc-filled-text-field-error-hover-active-indicator-color: var(
    --critical-text-light
  );

  --mat-form-field-subscript-text-size: 0.75rem;
  --mat-form-field-subscript-text-tracking: 0.033em;

  // Input field
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-outline-color: var(--form-border);
  --mdc-outlined-text-field-hover-outline-color: var(--form-border-hover);
  --mdc-outlined-text-field-hover-label-text-color: var(--form-border-hover);
  --mdc-outlined-text-field-input-text-color: var(--text-neutral-body);
  --mdc-outlined-text-field-focus-outline-color: var(--border-brand-default);
  --mdc-outlined-text-field-disabled-outline-color: var(--form-disabled);
  --mdc-outlined-text-field-disabled-label-text-color: var(--form-disabled);
  --mdc-outlined-text-field-disabled-input-text-color: var(--button-disabled);

  --mat-form-field-container-text-line-height: 1.5rem;
  --mat-form-field-container-text-size: 1rem;
}

*::placeholder {
  @apply form-label #{!important};
}

// asterisk
.mdc-floating-label--required:not(
    .mdc-floating-label--hide-required-marker
  )::after {
  @apply form-required;
}

.mdc-text-field {
  @apply surface-neutral-light;
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  @apply form-label;
}

.mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--focused)
  .mdc-floating-label {
  @apply text-neutral-caption;
}

// MDC has specific selectors for mdc-form-field with icon prefix/suffix
// with these rules we apply the same styling to our components
.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  min-height: 3rem;
  padding: 0.75rem 0;
}

.mat-mdc-paginator .mdc-text-field--outlined .mat-mdc-form-field-infix {
  min-height: 2rem;
}

.mat-mdc-form-field-icon-prefix > :is(cca-icon),
.mat-mdc-form-field-icon-suffix > :is(cca-icon),
.mat-mdc-form-field-icon-suffix > button > :is(cca-icon),
.mat-mdc-form-field-icon-suffix div > div > button > :is(cca-icon),
.mat-mdc-form-field-icon-suffix div > button > :is(cca-icon),
.mat-mdc-form-field-icon-suffix
  div
  .mtx-datetimepicker-toggle
  > button
  > :is(cca-icon) {
  @apply box-content items-center text-xl text-neutral-body;
  padding: 0.625rem 1rem;
}

.mat-mdc-form-field-icon-suffix
  div
  .mtx-datetimepicker-toggle
  > button.mat-mdc-button-disabled
  > :is(cca-icon) {
  @apply text-neutral-disabled;
}

.mat-mdc-form-field-icon-prefix {
  padding-right: 0 !important;

  > :is(cca-icon) {
    @apply text-neutral-caption;
  }
}

// While focused mat-form-field with appearance of outline, will somehow get a ugly border due to mdc-notched-outline having one
// this css rule will disable the border on the right
.mdc-notched-outline__notch {
  border-right: none;
}

/*  
  #########################################
  ########### MDC FIXES BELOW #############
  #########################################
*/

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 1.425rem !important;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined
  .mdc-notched-outline--upgraded
  .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(-29.5px)
    scale(var(--mat-mdc-form-field-floating-label-scale, 0.75)) !important;
  transform: var(--mat-mdc-form-field-label-transform);
}

.mat-mdc-form-field.mat-mdc-form-field-has-icon-suffix .mdc-floating-label {
  transform: var(
    --mat-mdc-form-field-label-transform,
    translateY(-50%)
      translateX(
        calc(1 * (56px + var(--mat-mdc-form-field-label-offset-x, 0px)))
      )
  ) !important;
}

.mat-mdc-form-field-hint-wrapper:has([ng-reflect-align='start']) {
  @apply px-0 pt-1 !important;
}
