:root {
  --mat-autocomplete-background-color: var(--surface-neutral-light);
}
.cdk-overlay-pane .mat-mdc-select-panel {
  border-radius: var(--mdc-shape-small) !important;
}

.cdk-overlay-pane:not(.mat-mdc-autocomplete-panel-above)
  .mat-mdc-autocomplete-panel {
  border-radius: var(--mdc-shape-small);
}

.mat-mdc-option:hover:not(.mdc-list-item--disabled) {
  @apply surface-brand-lighter;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) {
  @apply border-l-2 border-solid border-l-cca-green-600;
}
