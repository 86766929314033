.mat-mdc-radio-button,
.mdc-radio {
  --mat-radio-label-text-color: var(--text-neutral-body);
  --mat-radio-disabled-label-color: var(--text-neutral-disabled);
  --mat-radio-checked-ripple-color: var(--button-primary-default);
  --mdc-radio-selected-icon-color: var(--button-primary-default);
  --mat-radio-checked-focus-ripple-color: var(--button-primary-default);
  --mdc-radio-selected-focus-icon-color: var(--button-primary-default);
  --mat-radio-checked-hover-ripple-color: var(--selection-hover);
  --mdc-radio-selected-hover-icon-color: var(--selection-hover);
  --mdc-radio-unselected-icon-color: var(--selection-default);
  --mdc-radio-disabled-selected-icon-color: var(--border-neutral-disabled);
  --mdc-radio-disabled-unselected-icon-color: var(--border-neutral-disabled);
  --mdc-radio-disabled-selected-icon-opacity: 1;
  --mdc-radio-disabled-unselected-icon-opacity: 1;
  --mdc-radio-unselected-pressed-icon-color: var(--border-neutral-darker);
  --mdc-radio-unselected-hover-icon-color: var(--border-neutral-darker);
  .mdc-radio--disabled + label {
    color: var(--text-neutral-disabled);
  }
  .mat-internal-form-field {
    --mat-radio-label-text-color: var(--text-neutral-body);
  }
}

.mat-mdc-radio-group {
  display: flex;
  gap: 1rem;

  label {
    font-size: 1rem;
    font-weight: 500;
    @apply text-neutral-body;
  }
}
