.mat-mdc-snack-bar-container {
  border: 1px solid #2b1d53; // purple-900
  border-radius: 0.75rem;
  --mdc-snackbar-container-shape: 0.75rem;
  --mdc-snackbar-container-color: #efecf8; // purple-50
  --mdc-snackbar-supporting-text-color: #2b1d53; // purple-900
  --mat-snack-bar-button-color: #2b1d53; // purple-900
  --mdc-snackbar-supporting-text-size: 1rem;
  --mdc-snackbar-supporting-text-weight: 400;

  &.error {
    border: 1px solid #b91c1c; // red-500
    --mdc-snackbar-container-color: #f9e1e1; // red-50
    --mdc-snackbar-supporting-text-color: #610f0f; // red-900
    --mat-snack-bar-button-color: #610f0f; // red-900
  }

  &.success {
    border: 1px solid #8dab51; // green-500
    --mdc-snackbar-container-color: #e3f2c4; // green-50
    --mdc-snackbar-supporting-text-color: #3d5213; // green-900
    --mat-snack-bar-button-color: #3d5213; // green-900
  }

  button {
    padding-right: 0.25rem;
    font-weight: bold;
    &::after {
      margin-left: 1rem;
      content: '\e93f'; // circle-xmark icon
      font-size: 1.125rem;
      display: inline-block;
      font-family: CtrlChainIcons;
    }
  }
}
