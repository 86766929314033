:root {
  --mdc-chip-container-height: 2.5rem;
}
.mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: var(--success-text);
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--success-text);
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary,
.mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-elevated-container-color: var(--button-primary-default);
  --mdc-chip-elevated-selected-container-color: var(--button-primary-default);
  --mdc-chip-elevated-disabled-container-color: var(--button-primary-default);
  --mdc-chip-flat-disabled-selected-container-color: var(
    --button-primary-default
  );
}

.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
  --mdc-chip-outline-color: var(--surface-neutral-darker);
  --mdc-chip-elevated-container-color: var(--surface-neutral-darker);
}
.cca-chip.mat-mdc-standard-chip.mdc-evolution-chip--selected:not(
    .mdc-evolution-chip--disabled
  ) {
  border-width: 1px;
  border-style: solid;
  border-color: var(--border-brand-default);
  background-color: var(--success-surface-lighter);
}
.cca-chip.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled)
  .mdc-evolution-chip__checkmark,
.cca-chip.mat-mdc-standard-chip.mdc-evolution-chip--selected:not(
    .mdc-evolution-chip--disabled
  )
  .mdc-evolution-chip__text-label {
  font-weight: 500;
  color: var(--success-text);
}
