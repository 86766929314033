:root {
  --mdc-plain-tooltip-container-shape: 0.25rem;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
  --mdc-plain-tooltip-container-color: var(--neutral-surface-darker);
  --mdc-plain-tooltip-supporting-text-color: white;
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: 0.0333333333em;
}
.mdc-tooltip__surface {
  text-align: left !important;
  padding: 0.75rem !important;
  line-height: 1.25rem !important;
}

.multiline-tooltip {
  white-space: pre-line;
}
