cca-search-field .mdc-text-field,
cca-address-autocomplete .mdc-text-field {
  @apply rounded-lg;
}

cca-search-field .mat-mdc-text-field-wrapper,
.cca-search-field .mat-mdc-text-field-wrapper,
cca-address-autocomplete .mat-mdc-text-field-wrapper {
  @apply surface-neutral-default;
}

cca-search-field.w-full .mat-mdc-form-field {
  width: 100%;
}

cca-search-field .mat-mdc-form-field-icon-suffix > button > :is(cca-icon) {
  @apply text-neutral-caption;
}

cca-search-field
  .mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__leading,
cca-search-field
  .mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__notch,
cca-search-field
  .mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__trailing,
cca-address-autocomplete
  .mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__leading,
cca-address-autocomplete
  .mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__notch,
cca-address-autocomplete
  .mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__trailing {
  @apply border-neutral-default;
}
