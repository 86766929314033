@use '../../../shared/styles/ui';
@use '../../../shared/styles/tailwind/tailwind';
@use '../../../shared/styles/main';
@use '../../../shared/assets/icons/CtrlChain.css';
@use '../../../shared/styles/libs/ng-select';
@use '../../../shared/styles/libs/bryntum-scheduler';
@use '../../../shared/styles/libs/tippy';

@layer base {
  html {
    height: 100%;
  }

  body,
  cca-root {
    min-height: 100%;
    font-size: 16px; // 1rem = 16 this comment breaks css :D
    height: 100%;
    position: relative;
  }

  body {
    @apply text-neutral-body;
    @apply surface-neutral-default;
  }

  body::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../../shared/assets/images/background.svg');
    background-size: 900px 900px;
    background-repeat: no-repeat;
    background-position: bottom -240px right -240px;
    opacity: 0.1;
  }

  a,
  button,
  .pointer {
    cursor: pointer;
  }

  button:focus {
    outline: none;
  }

  table {
    @apply border-separate border-spacing-0;
    tbody > tr:hover {
      @apply surface-neutral-default;
    }
  }
}

@layer utilities {
  .w-inherit {
    width: inherit;
  }

  .h-inherit {
    height: inherit;
  }

  .menu-no-scroll {
    overflow: hidden !important;
  }
}

@layer components {
  .login-gradient {
    background: linear-gradient(0deg, #678230, #8caa50);
  }

  .bg-unset {
    background: unset;
  }

  .auth-card {
    max-width: 600px;
    width: 600px;
    height: 400px;
    max-height: 400px;
  }

  //common styles
  .page-container {
    @apply relative overflow-hidden rounded-xl border p-6 surface-neutral-light border-neutral-default;
  }

  .table-container {
    @apply overflow-auto;
    width: inherit;
    max-height: calc(100% - 9.1rem);
    height: inherit;
  }

  .menu-bar + * {
    height: calc(100% - 4rem) !important;

    > *:not(router-outlet) {
      height: 100%;
    }
  }

  .link-auth {
    @apply text-brand-light;
  }

  .spinner {
    text-align: center;
  }

  .expansion-no-padding {
    .mat-expansion-panel-content {
      .mat-expansion-panel-body {
        padding: 0px;
      }
    }
  }
}

.hidden {
  opacity: 0;
  pointer-events: none;
}

p.noResult {
  text-align: center;
  margin: auto;
  padding: 3rem 1rem;
  @apply text-base text-neutral-subtitle;
}

*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

*:state(webkit-scrollbar-track) {
  @apply surface-neutral-light;
}

*::-webkit-scrollbar-thumb {
  @apply rounded surface-neutral-darker;
}

*::-webkit-scrollbar-corner {
  @apply surface-neutral-light;
}

// Gridster Styles
gridster {
  overflow: hidden;
}
gridster.display-grid .gridster-column {
  @apply border-x border-solid border-neutral-default;
  outline: 7px solid var(--border-neutral-default);
}
gridster.display-grid .gridster-row {
  @apply border-y border-solid border-neutral-default;
  outline: 7px solid var(--border-neutral-default);
}
.gridster-preview {
  @apply surface-neutral-darker;
}
