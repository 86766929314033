:root {
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mdc-dialog-container-color: var(--surface-neutral-light);
  --mdc-dialog-container-shape: 0.75rem;
}

.dialog-container {
  @apply flex h-full flex-col justify-between;
  header {
    @apply flex justify-between border-b px-6 py-4 border-neutral-default;
    h2 {
      @apply text-xl font-medium text-neutral-body;
    }
    p {
      @apply text-sm text-neutral-subtitle;
    }
    .title-wrapper {
      @apply flex flex-col;
    }
    .dialog-close-button {
      @apply flex h-7 w-6 items-center text-xl text-neutral-caption;
      cca-icon {
        @apply leading-4;
      }
    }
  }

  main {
    @apply p-6;
    max-height: 65vh;
    min-width: 30rem;
    overflow: auto;
  }

  footer {
    @apply mt-auto flex justify-center gap-6 border-t p-6 border-neutral-default;
  }
}
