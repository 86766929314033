@import 'mixins';

$ng-select-highlight: var(--text-brand-default) !default;
$ng-select-primary-text: var(--text-neutral-body) !default;
$ng-select-primary-light-text: rgba(white, 0.87) !default;
$ng-select-secondary-light-text: rgba(white, 0.54) !default;
$ng-select-disabled-text: var(--form-disabled) !default;
$ng-select-divider: var(--surface-neutral-default) !default;
$ng-select-bg: var(--surface-neutral-light) !default;

.ng-select.ng-select-custom {
  &.ng-invalid.ng-touched .ng-select-container.ng-appearance-outline {
    box-shadow: none !important;
    &::after {
      border-width: 1px;
      @apply critical-border-dark;
    }
    .ng-value-container .ng-placeholder,
    .ng-placeholder {
      @apply critical-text-light;
    }

    // .ng-clear-wrapper .ng-clear::before {
    //   color: var(--mat-form-field-error-text-color) !important;
    // }
    // .ng-arrow-wrapper::before {
    //   color: var(--mat-form-field-error-text-color) !important;
    // }
  }
  .ng-clear-wrapper {
    width: 28px !important;
    height: 20px;
    @apply surface-neutral-light;
    border-radius: 50px;
    .ng-clear {
      font-size: 0 !important;

      &::before {
        font-family: CtrlChainIcons;
        position: relative;
        display: inline-block;
        font-style: normal;
        font-weight: normal;
        line-height: 1;
        content: '\e93f'; // xmark icon
        font-size: 20px;
      }
    }
  }
  .ng-arrow-wrapper {
    width: 20px;
    height: 20px;

    .ng-arrow {
      display: none !important;
    }
    &::before {
      font-family: CtrlChainIcons;
      position: relative;
      display: inline-block;
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: '\e930'; // chevron-down icon
      font-size: 20px;
      color: var(--text-neutral-caption);
    }
  }
  &.ng-select-opened {
    .ng-arrow-wrapper::before {
      content: '\e937' !important; // chevron-up icon
    }

    .ng-select-container {
      &.ng-appearance-outline {
        &:after {
          @apply border-brand-default #{!important};
          border-width: 0.094rem;
        }
      }
      .ng-value-container {
        .ng-placeholder {
          @apply text-cca-green-500;
        }
      }
    }
  }

  &.ng-select-disabled {
    .ng-select-container {
      &:after {
        border: solid 1px $ng-select-disabled-text !important;
        border-bottom-color: transparent;
        background-size: 4px 1px;
        background-repeat: repeat-x;
      }
      .ng-value-container {
        .ng-value {
          color: $ng-select-disabled-text;
        }
        .ng-placeholder {
          color: $ng-select-disabled-text;
        }
      }
    }
    .ng-clear-wrapper .ng-clear::before {
      color: $ng-select-disabled-text;
    }
    .ng-arrow-wrapper::before {
      color: $ng-select-disabled-text;
    }
  }
  &.ng-select-focused {
    .ng-select-container {
      &:after {
        border-color: var(--mdc-outlined-text-field-focus-outline-color);
        border-width: 2px;
      }
      &.ng-appearance-outline {
        &:after,
        &:hover:after {
          border-color: var(--mdc-outlined-text-field-focus-outline-color);
          border-width: 2px;
        }
      }
      .ng-value-container .ng-placeholder {
        @apply text-neutral-caption;
      }
      .ng-arrow-wrapper {
        color: $ng-select-highlight;
        @apply text-neutral-caption;
      }
    }
  }
  &.ng-select-required {
    .ng-placeholder {
      &:after {
        content: '*';
        margin-left: 1px;
        margin-right: 0px;
        @apply text-sm;
        @apply form-required;
      }
    }
  }
  &:not(.ng-select-show-chips) {
    .ng-value-container {
      flex-wrap: nowrap !important;
    }
  }
  .ng-has-value .ng-input {
    display: none;
  }
  .ng-has-value,
  &.ng-select-filtered .ng-select-container {
    .ng-placeholder {
      display: initial;
    }
  }
  .ng-select-container {
    color: $ng-select-primary-text;
    align-items: baseline;
    min-height: 48px;
    align-items: center;

    &:after {
      border-bottom: thin solid rgba(0, 0, 0, 0.42);
      content: '';
      bottom: 0;
      left: 0;
      right: 0;
      position: absolute;
      transition: border-color 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
    }
    &.ng-appearance-outline {
      overflow: visible !important;
      padding: 0.5rem 1rem;
      min-height: 48px;
      &:after {
        border: solid 1px var(--mdc-outlined-text-field-outline-color);
        border-radius: var(--mdc-shape-small);
        height: calc(100%);
        pointer-events: none;
        transition: border-color 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
      }
      &:hover:after {
        border-color: $ng-select-primary-text;
        border-width: 1px;
      }
      .ng-placeholder {
        @apply text-neutral-caption;
        background-color: $ng-select-bg;
        z-index: 1;
      }
    }
    .ng-value-container {
      gap: 0.25rem;
      .ng-placeholder {
        position: absolute;
        @apply text-neutral-caption;
        // transform-origin: left 0;
        // transform: translateY(-1.28125em) scale(0.75) perspective(100px)
        // translateZ(0.001px);
        top: -10px;
        left: 15px;
        font-size: 12px;
        padding: 0 4px;
        height: 1rem;
        @include rtl {
          transform-origin: right 0;
        }
      }
      .ng-input {
        display: none;
      }
    }
  }

  &.ng-select-single {
    &.ng-select-filtered .ng-select-container .ng-value-container .ng-value {
      visibility: visible !important;
    }
    .ng-select-container {
      .ng-value-container {
        .ng-value {
          margin: 0 0.25em 0 0;

          @include rtl {
            margin: 0 0 0.4375em 0.4375em;
          }
        }
      }
    }
  }
  &.ng-select-multiple {
    &.ng-select-disabled {
      .ng-select-container .ng-value-container .ng-value {
        @apply surface-neutral-dark #{!important};
        color: $ng-select-disabled-text;
      }
      .ng-appearance-outline {
        &:after,
        &:hover:after {
          background-image: none;
          border: solid 1px $ng-select-disabled-text;
        }
      }
    }
    .ng-value-container {
      margin-left: -0.5rem;
    }
    .ng-select-container {
      .ng-value-container {
        min-width: 8rem;
        .ng-value {
          @apply surface-neutral-dark;
          border-radius: var(--mdc-shape-small);
          border: none !important;
          color: $ng-select-primary-text;
          padding: 4px 0.5em;
          margin: 0;
          align-items: center;
          text-overflow: ellipsis !important;
          display: inline;
          white-space: nowrap;
          overflow: hidden;
          max-width: 90%;
          display: flex;

          @include rtl {
            margin: 0 0 0.4375em 0.4375em;
          }
          &.ng-value-disabled {
            background-color: $ng-select-divider;
            color: rgba(0, 0, 0, 0.26);
          }
          .ng-value-label {
            font-size: 14px;
            font-weight: 500;
          }
          .ng-value-icon {
            color: $ng-select-secondary-light-text;
            padding-right: 5px;
            @include rtl {
              padding-left: 5px;
              padding-right: 0;
            }
            &:hover {
              color: $ng-select-primary-light-text;
            }
          }
          &:hover {
            @apply surface-brand-lighter;
          }
        }
        .ng-input {
          display: none;
        }
      }
      &.ng-has-value {
        align-items: center;
      }
    }
  }
  .ng-clear-wrapper {
    @apply text-neutral-caption;
    &:hover {
      color: $ng-select-primary-text;
    }
    &:focus {
      color: $ng-select-primary-text;
      outline: none;
    }
  }
  .ng-arrow-wrapper {
    .ng-arrow {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid;
      margin: 0 4px;
      @apply text-neutral-caption;
    }
  }
  .ng-spinner-zone {
    top: 3px;
  }
}

.ng-dropdown-panel.ng-select-custom {
  max-width: 550px;
  background: $ng-select-bg;
  left: 0;
  overflow: hidden;
  border-radius: var(--mdc-shape-small);

  .ng-dropdown-header {
    padding: 0 10px !important;
    border-bottom: none !important;
  }

  &.ng-select-top {
    bottom: calc(100% - 0.84375em);
    box-shadow:
      0 -5px 5px -3px rgba(0, 0, 0, 0.2),
      0 -8px 10px 1px rgba(0, 0, 0, 0.14),
      0 -3px 14px 2px $ng-select-divider;
  }
  &.ng-select-right {
    left: 100%;
    top: calc(0% + 0.84375em);
    box-shadow:
      0 -5px 5px -3px rgba(0, 0, 0, 0.2),
      0 -8px 10px 1px rgba(0, 0, 0, 0.14),
      0 -3px 14px 2px $ng-select-divider;
    margin-left: 4px;
  }
  &.ng-select-bottom {
    top: calc(100% - 1.25em);
    box-shadow:
      0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 8px 10px 1px rgba(0, 0, 0, 0.14),
      0 3px 14px 2px $ng-select-divider;
  }
  &.ng-select-left {
    left: calc(-100% - 4px);
    top: calc(0% + 0.84375em);
    box-shadow:
      0 -5px 5px -3px rgba(0, 0, 0, 0.2),
      0 -8px 10px 1px rgba(0, 0, 0, 0.14),
      0 -3px 14px 2px $ng-select-divider;
  }
  &.multiple {
    .ng-option {
      &.selected {
        background-color: var(--mat-option-selected-state-layer-color);
      }
      &.marked {
        @apply surface-brand-lighter;
      }
    }
  }
  .ng-dropdown-header {
    border-bottom: 1px solid $ng-select-divider;
    padding: 0 16px;
    line-height: 3em;
    min-height: 3em;
  }
  .ng-dropdown-footer {
    border-top: 1px solid $ng-select-divider;
    padding: 0 16px;
    line-height: 3em;
    min-height: 3em;
  }
  .ng-dropdown-panel-items {
    .ng-optgroup {
      user-select: none;
      cursor: pointer;
      line-height: 3em;
      height: 3em;
      padding: 0 16px;
      font-size: 14px;
      text-transform: uppercase;
      @apply text-neutral-disabled;
      font-weight: 400;
      &.ng-option-marked {
        @apply surface-brand-lighter #{!important};
      }
      &.ng-option-disabled {
        cursor: default;
      }
      &.ng-option-selected {
        background-color: var(--mat-option-selected-state-layer-color);
        color: $ng-select-highlight;
        @apply border-l-2 border-solid border-l-cca-green-600;
      }
    }
    .ng-option {
      min-height: 3em;
      white-space: nowrap;
      display: flex;
      align-items: center;
      justify-content: space-between;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 8px 16px;
      text-decoration: none;
      position: relative;
      color: $ng-select-primary-text;
      text-align: left;

      .mat-mdc-checkbox {
        margin-left: -8px;
      }

      &.loading-notfound-text {
        @apply text-center;
        @apply text-neutral-subtitle;
      }

      .mat-mdc-checkbox label {
        text-wrap: wrap;
        overflow-wrap: anywhere;
      }

      @include rtl {
        text-align: right;
      }
      &.ng-option-marked {
        @apply surface-brand-lighter #{!important};
        color: $ng-select-primary-text !important;
      }
      &.ng-option-selected {
        background-color: var(--mat-option-selected-state-layer-color);
        color: $ng-select-highlight;
        @apply border-l-2 border-solid border-l-cca-green-600;
        .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
        .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
          @apply text-brand-default;
        }
      }
      &.ng-option-disabled {
        //color: $ng-select-disabled-text;
        @apply text-neutral-subtitle;
      }
      &.ng-option-child {
        padding-left: 16px;
        @include rtl {
          padding-right: 32px;
          padding-left: 0;
        }
      }
      .ng-tag-label {
        padding-right: 5px;
        @include rtl {
          padding-left: 5px;
          padding-right: 0;
        }
        font-size: 80%;
        font-weight: 400;
        color: $ng-select-disabled-text;
      }
    }
  }
}

cca-auto-complete-field {
  mat-error {
    font-size: var(--mat-form-field-subscript-text-size);
    padding: 0 16px;
    @apply critical-text-light;
  }
}
