.mat-drawer:not(:has(cca-notifications-panel)) {
  min-width: 40%;
  max-width: 50%;
}
.mat-drawer:has(cca-notifications-panel) {
  width: 26.5rem;
}
.mat-drawer:has(cca-filters-drawer) {
  z-index: 1000;
}
.cdk-overlay-container:has(cca-dialog-addresses) {
  z-index: 950;
}
