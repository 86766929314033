.drag-list {
  width: 100%;
  max-width: 100%;
  min-height: 3.75rem;
  display: flex;
  border-radius: 0.5rem;
  overflow: hidden;
  flex-direction: column;
  gap: 0.25rem;
  @apply surface-neutral-light;
}

.drag-box {
  padding: 0.25rem 0.5rem 0.25rem 0.25rem;
  @apply border-l-2 border-solid border-neutral-invert;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: move;
  @apply surface-neutral-default;
  font-size: 0.875rem;
}

.drag-box:hover {
  @apply border-l-2 surface-neutral-default border-brand-default;
}

.cdk-drag-preview:not(cca-task-card) {
  box-sizing: border-box;
  @apply border-l-2 border-solid surface-brand-lightest border-brand-default;
  box-shadow:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.drag-list.cdk-drop-list-dragging .drag-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.drag-placeholder {
  @apply surface-neutral-darker;
  border: dotted 2px var(--border-neutral-default);
  min-height: 50px;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
