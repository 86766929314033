.mat-mdc-tab-group,
.mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: var(--border-brand-default);
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: var(--text-brand-default);
  --mat-tab-header-active-ripple-color: var(--text-brand-default);
  --mat-tab-header-inactive-ripple-color: var(--text-brand-default);
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: var(--text-brand-default);
  --mat-tab-header-active-hover-label-text-color: var(--text-brand-default);
  --mat-tab-header-active-focus-indicator-color: var(--text-brand-default);
  --mat-tab-header-active-hover-indicator-color: var(--text-brand-default);
  --mat-tab-header-active-label-text-color: var(--text-brand-default);
  --mat-tab-header-active-ripple-color: var(--surface-brand-default);
  --mat-tab-header-inactive-label-text-color: var(--text-neutral-caption);
  --mat-tab-header-inactive-hover-label-text-color: var(
    --text-neutral-subtitle
  );
  --mat-tab-header-divider-color: var(--border-neutral-default);
}

.mdc-tab--active {
  --mat-tab-header-label-text-weight: 500;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-size: 1rem;
  --mat-tab-header-label-text-tracking: 0;
  --mat-tab-header-label-text-weight: 500;
}

.mat-mdc-tab-body-content {
  overflow-x: hidden !important;
}

.mat-mdc-tab-body-wrapper {
  height: inherit;
}
